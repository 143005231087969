import { Component, OnInit, Inject } from '@angular/core';
import { UserData } from 'app/providers/user-data';
import { StorageService, LOCAL_STORAGE } from 'ngx-webstorage-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  profileInfo: {
    fullname?: string,
    email?: string,
    password?: string
  } = {};
  constructor(public user: UserData, @Inject(LOCAL_STORAGE) private storage: StorageService, public router: Router) {
    if (storage.get(this.user.HAS_LOGGED_IN)) {
      var data = this.storage.get(this.user.PROFILE)
      this.profileInfo.fullname = data.name;
      this.profileInfo.email = data.username;
    } else {
      router.navigate(['/login'])
    }


  }

  ngOnInit() {

  }

}
