import { global } from './../app.global';
import { Injectable, Inject } from '@angular/core';
import { Http, RequestOptions, URLSearchParams, Headers } from '@angular/http';
import { UserData } from './user-data';
import { Router } from '@angular/router';
import { StorageService, LOCAL_STORAGE } from 'ngx-webstorage-service';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiUrl: String = global.apiUrl;
  apiKey: any;
  // OWNWYldiUmhHSWxld0U1VDNaZ05TYnRoTVJxWnZUOFk=
  constructor(private http: Http, public user: UserData, @Inject(LOCAL_STORAGE) private storage: StorageService, public router: Router) {
    this.apiKey = this.storage.get(this.user.APIKEY)
  }
  get(endpoint: string, params?: any, options?: RequestOptions) {
    if (!options) {
      let headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.user.apiKey
      });
      options = new RequestOptions({ headers: headers, method: "get" });
    }

    // Support easy query params for GET requests
    if (params) {
      let p = new URLSearchParams();
      for (let k in params) {
        p.set(k, params[k]);
      }
      // Set the search field if we have params and don't already have a search field
      // set in options.
      options.search = !options.search && p || options.search;
    }

    return this
      .http
      .get(this.apiUrl + '/' + endpoint, options);
  }
  post(endpoint: string, body: any, options?: RequestOptions) {
    if (!options) {
      let headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.user.apiKey
      });
      options = new RequestOptions({ headers: headers, method: "post" });
    }
    return this
      .http
      .post(this.apiUrl + '/' + endpoint, body, options);
  }
  postAsFormData(endpoint: string, body: any, options?: RequestOptions) {
    if (!options) {
      let headers = new Headers({
        // 'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.user.apiKey
      });
      options = new RequestOptions({ headers: headers, method: "post" });
    }
    return this
      .http
      .post(this.apiUrl + '/' + endpoint, body, options);
  }
  postAsJson(endpoint: string, body: any, options?: RequestOptions) {
    if (!options) {
      let headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.user.apiKey
      });
      options = new RequestOptions({ headers: headers, method: "post" });
    }
    return this
      .http
      .post(this.apiUrl + '/' + endpoint, body, options);
  }

  put(endpoint: string, body: any, options?: RequestOptions) {
    if (!options) {
      let headers = new Headers({
        // 'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + this.user.apiKey
      });
      options = new RequestOptions({ headers: headers, method: "put" });
    }
    return this
      .http
      .put(this.apiUrl + '/' + endpoint, body, options);
  }

  delete(endpoint: string, options?: RequestOptions) {
    if (!options) {
      let headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.user.apiKey
      });
      options = new RequestOptions({ headers: headers, method: "get" });
    }
    return this
      .http
      .delete(this.apiUrl + '/' + endpoint, options);
  }

  patch(endpoint: string, body: any, options?: RequestOptions) {
    return this
      .http
      .put(this.apiUrl + '/' + endpoint, body, options);
  }
}
