import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(public api: ApiService) { }
  //CUSTOMER GROUP START
  getGroup(page: number = 0, per_page: number = 0) {
    let endpoint = 'admin/customers/groups';
    if (page > 0)
      endpoint += '?page=' + page
    if (per_page > 0)
      endpoint += '&per_page=' + per_page;
    return this
      .api
      .get(endpoint)
      .map(resp => resp.json());
  }

  addGroup(data, isEdit) {
    let endpoint = 'admin/customers/groups';
    if (isEdit) endpoint += '/' + data.id;
    if (isEdit) {
      return this
        .api
        .put(endpoint, data)
        .map(resp => resp.json());
    } else {
      return this
        .api
        .post(endpoint, data)
        .map(resp => resp.json());
    }

  }
  deleteGroup(id) {
    let endpoint = 'admin/customers/groups/delete/' + id;
    return this
      .api
      .delete(endpoint)
      .map(resp => resp.json());
  }
  // END CUSTOMER GROUP
  // START CUSTOMER
  getCustomer(page: number = 0, per_page: number = 0,data:any=null) {
    console.log(data.customerId)
    let endpoint = 'admin/customers';
    if (page > 0)
      endpoint += '?page=' + page
    if (per_page > 0)
      endpoint += '&per_page=' + per_page;
      if(data.customerName !=null)
      endpoint =endpoint+'&customer_name='+data.customerName;
      if(data.customerEmail !=null)
      endpoint =endpoint+'&customer_email='+data.customerEmail;
      if(data.customerMobile !=null)
      endpoint =endpoint+'&customer_mobile='+data.customerMobile;
      if(data.customerId !=null)
      endpoint =endpoint+'&customer_id='+data.customerId;
    return this
      .api
      .get(endpoint)
      .map(resp => resp.json());
  }

  addCustomer(data, isEdit) {
    let endpoint = 'admin/customers';
    if (isEdit) endpoint += '/' + data.id;
    if (isEdit) {
      return this
        .api
        .put(endpoint, data)
        .map(resp => resp.json());
    } else {
      return this
        .api
        .post(endpoint, data)
        .map(resp => resp.json());
    }

  }
 
  viewCustomer(id) {
    let endpoint = 'admin/customers/' + id;
    return this
      .api
      .get(endpoint)
      .map(resp => resp.json());
  }
  deleteCustomer(id) {
    let endpoint = 'admin/customers/delete/' + id;
    return this
      .api
      .delete(endpoint)
      .map(resp => resp.json());
  }
  // END CUSTOMER
  // START CUSTOMER GROPUP ASSOCIATION
  getGroupAssociation(page: number = 0, per_page: number = 0) {
    let endpoint = 'admin/customers/group-association';
    if (page > 0)
      endpoint += '?page=' + page
    if (per_page > 0)
      endpoint += '&per_page=' + per_page;
    return this
      .api
      .get(endpoint)
      .map(resp => resp.json());
  }

  addGroupAssociation(data, isEdit) {
    let endpoint = 'admin/customers/group-association';
    if (isEdit) endpoint += '/' + data.id;
    if (isEdit) {
      return this
        .api
        .put(endpoint, data)
        .map(resp => resp.json());
    } else {
      return this
        .api
        .post(endpoint, data)
        .map(resp => resp.json());
    }
  }
  deleteGroupAssociation(id) {
    let endpoint = 'admin/customers/group-association/delete/' + id;
    return this
      .api
      .delete(endpoint)
      .map(resp => resp.json());
  }
  getFavourites(page: number = 0, per_page: number = 0) {
    let endpoint = 'admin/customers/favourites';
    if (page > 0)
      endpoint += '?page=' + page
    if (per_page > 0)
      endpoint += '&per_page=' + per_page;
    return this
      .api
      .get(endpoint)
      .map(resp => resp.json());
  }
  // START ANJOS COIN
  getCoin(page: number = 0, per_page: number = 0) {
    let endpoint = 'admin/wallet/coins';
    if (page > 0)
      endpoint += '?page=' + page
    if (per_page > 0)
      endpoint += '&per_page=' + per_page;
    return this
      .api
      .get(endpoint)
      .map(resp => resp.json());
  }
  addCoin(data, isEdit) {
    let endpoint = 'admin/wallet/coins';
    if (isEdit) endpoint += '/' + data.id;
    if (isEdit) {
      return this
        .api
        .put(endpoint, data)
        .map(resp => resp.json());
    } else {
      return this
        .api
        .post(endpoint, data)
        .map(resp => resp.json());
    }

  }
  
  deleteCoin(id) {
    let endpoint = 'admin/wallet/coins/delete/' + id;
    return this
      .api
      .delete(endpoint)
      .map(resp => resp.json());
  }

  addAddress(data, isEdit) {
    let endpoint = 'admin/customers/customer-address';
    if (isEdit) endpoint += '/' + data.id;
    if (isEdit) {
      return this
        .api
        .put(endpoint, data)
        .map(resp => resp.json());
    } else {
      return this
        .api
        .post(endpoint, data)
        .map(resp => resp.json());
    }
}

addSpinWheelProducts(data, isEdit) {
  let endpoint = 'admin/customers/customer-address';
  if (isEdit) endpoint += '/' + data.id;
  if (isEdit) {
    return this
      .api
      .put(endpoint, data)
      .map(resp => resp.json());
  } else {
    return this
      .api
      .post(endpoint, data)
      .map(resp => resp.json());
  }
}

getAddress(id) {
  let endpoint = 'admin/customers/show-address/'+id;
  return this
    .api
    .get(endpoint)
    .map(resp => resp.json());
}
getSpinWheel(id) {
  let endpoint = 'admin/spin_wheel_history/spin-history/'+id;
  return this
    .api
    .get(endpoint)
    .map(resp => resp.json());
}
deleteAddress(id) {
  let endpoint = 'admin/customers/customer-address/delete/' + id;
  return this
    .api
    .delete(endpoint)
    .map(resp => resp.json());
}
getSubscribers(id) {
  let endpoint = 'admin/customers/plan-subscribers/' + id;
  return this
    .api
    .get(endpoint)
    .map(resp => resp.json());
}
getfavourite(id,ref_type='') {
  let endpoint = 'admin/customers/favourites/details/' + id;
  if(ref_type != '') endpoint +='?ref_type='+ref_type
  return this
    .api
    .get(endpoint)
    .map(resp => resp.json());
}

searchCustomer(value,page:number=0,per_page:number=0){
  console.log(value)
      let endpoint = 'admin/customers?';
      if(page >0)
      endpoint=endpoint+'page=' + page;
      if(per_page >0)
      endpoint =endpoint+'&per_page=' + per_page;
      if(value !=null)
      endpoint=endpoint+'&customer_name='+value;
      return this
      .api
      .get(endpoint)
      .map(resp=>resp.json());
}
}
