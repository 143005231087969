import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Profile } from 'app/providers/profile.service';
import { MatTableDataSource } from '@angular/material';
import { CommonProvider } from 'app/providers/common';
import { Router, ActivatedRoute } from '@angular/router';
import { element } from '@angular/core/src/render3';

@Component({
  selector: 'app-add-role-group',
  templateUrl: './add-role-group.component.html',
  styleUrls: ['./add-role-group.component.css']
})
export class AddRoleGroupComponent implements OnInit {
  addRoleForm: FormGroup;
  submitted: boolean = false;
  isEdit: boolean = false;
  page: number = 1;
  per_page: number = 10;
  total: number = 0;
  permissions: any = []
  roleDetails: any = []
  id: any;
  constructor(private formBuilder: FormBuilder, private profile: Profile, private common: CommonProvider,
    public router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.profile.getPermissions().subscribe(response => {
      if (response.success == true) {
        this.total = response.data.total;
        this.permissions = response.data;
      } else { }
      this.route.queryParams
        .filter(params => params.id)
        .subscribe(params => {
          if (params.id != null) {
            this.id = params.id;
            this.isEdit = true;
            this.loadRoleDetails(params.id)
          }
        });
    }, err => {
    });

    this.addRoleForm = this.formBuilder.group({
      role_name: ['', Validators.required],
    }, {});
  }

  get arf() { return this.addRoleForm.controls; }

  expand(p: any) {
    p.expanded = !p.expanded;
  }

  loadPermissions() {
    this.profile.getPermissions().subscribe(response => {
      if (response.success == true) {
        this.total = response.data.total;
        this.permissions = response.data;
      } else { }
    }, err => {
    });
  }
  loadRoleDetails(id) {
    this.profile.getRoleGroupDetails(id).subscribe(response => {
      if (response.success == true) {
        this.roleDetails = response.data;
        console.log(this.permissions)
        this.arf.role_name.patchValue(this.roleDetails.name)
        this.roleDetails.permission_ids.forEach(element => {
          this.permissions.forEach(prm => {
            if (element == prm._id) {
              prm.expanded = true
              prm.isChecked = true
            }
            prm.children.forEach(prm1 => {
              if (element == prm1._id) {
                prm.expanded = true
                prm1.expanded = true
                prm1.isChecked = true
              }
              prm1.children.forEach(prm2 => {
                if (element == prm2._id) {
                  prm.expanded = true
                  prm1.expanded = true
                  prm2.expanded = true
                  prm2.isChecked = true
                }
                prm2.children.forEach(prm3 => {
                  if (element == prm3._id) {
                    prm.expanded = true
                    prm1.expanded = true
                    prm2.expanded = true
                    prm3.isChecked = true
                  }
                })
              })
            })
          })
        })
      } else { }
    }, err => {
    });
  }
  selectAll(p) {
    // if (this.isEdit) {
    if (p.isChecked) {
      if (p.children && p.children.length > 0) {
        p.children.forEach(element => {
          element.isChecked = true
          if (element.children && element.children.length > 0) {
            element.children.forEach(x => {
              x.isChecked = true
              if (x.children && x.children.length > 0) {
                x.children.forEach(y => {
                  y.isChecked = true
                  if (y.children && y.children.length > 0) {
                    y.children.forEach(z => {
                      z.isChecked = true
                    });
                  }
                });
              }
            });
          }
        });
      }
    }
    else {
      if (p.children && p.children.length > 0) {
        p.children.forEach(element => {
          element.isChecked = false
          if (element.children && element.children.length > 0) {
            element.children.forEach(x => {
              x.isChecked = false
              if (x.children && x.children.length > 0) {
                x.children.forEach(y => {
                  y.isChecked = false
                  if (y.children && y.children.length > 0) {
                    y.children.forEach(z => {
                      z.isChecked = false
                    });
                  }
                });
              }
            });
          }
        });
      }
    }
    // } else {
    //   if (p.children && p.children.length > 0) {
    //     p.children.forEach(element => {
    //       element.isChecked == true ? element.isChecked = false : element.isChecked = true;
    //       if (element.children && element.children.length > 0) {
    //         element.children.forEach(x => {
    //           x.isChecked == true ? x.isChecked = false : x.isChecked = true;
    //           if (x.children && x.children.length > 0) {
    //             x.children.forEach(y => {
    //               y.isChecked == true ? y.isChecked = false : y.isChecked = true;
    //               if (y.children && y.children.length > 0) {
    //                 y.children.forEach(z => {
    //                   z.isChecked == true ? z.isChecked = false : z.isChecked = true;
    //                 });
    //               }
    //             });
    //           }
    //         });
    //       }
    //     });
    //   }
    // }
  }
  onAddRole() {
    this.submitted = true;
    if (this.addRoleForm.valid) {
      var data = {
        role: this.arf.role_name.value,
      };
      var x = [];
      this.permissions.forEach(element => {
        if (element.isChecked) x.push(element.name)
        element.children.forEach(element1 => {
          if (element1.isChecked) x.push(element1.name)
          element1.children.forEach(element2 => {
            if (element2.isChecked) x.push(element2.name)
            element2.children.forEach(element3 => {
              if (element3.isChecked) x.push(element3.name)
              element3.children.forEach(element4 => {
                if (element4.isChecked) x.push(element4.name)
              })
            })
          })
        })
      })
      data['permissions'] = x;
      if (this.isEdit) data['id'] = this.roleDetails._id;
      this.profile.addRole(data, this.isEdit).subscribe(response => {
        if (response.success == true) {
          this.router.navigate(['/user/role-group'])
        } else {
        }
      }, err => {
        console.log('ERRRRROR')
        console.log(err)
        this.common.processError(err)
      });
    }
  }



  checkAvailability(data) {
    if (data == 'catalog') {
      if (this.common.is_catalog) {
        return true
      }
    }
    else if (data == 'sales') {
      if (this.common.is_order) {
        return true
      }
    }
    else if (data == 'customers') {
      if (this.common.is_customer) {
        return true
      }
    }
    else if (data == 'customers') {
      if (this.common.is_customer) {
        return true
      }
    }
    else if (data == 'vendors') {
      if (this.common.is_vendor) {
        return true
      }
    }
    else if (data == 'settings') {
      if (this.common.is_settings) {
        return true
      }
    }
    else if (data == 'delivery-managment') {
      if (this.common.is_delivery_boy) {
        return true
      }
    }
    else if (data == 'social-media') {
      if (this.common.is_social_media) {
        return true
      }
    }

    else{
      return true
    }

  }


  checkSubAvailability(data){
    if (data == 'promotional-banners') {
      if (this.common.is_promotion_banner) {
        return true
      }
    }
   else if (data == 'offers') {
      if (this.common.is_offer) {
        return true
      }
    }
    else if (data == 'membership') {
      if (this.common.is_customer_membership) {
        return true
      }
    }
    else if (data == 'delivery-boy') {
      if (this.common.is_delivery_boy) {
        return true
      }
    }

    else if (data == 'lunchbox') {
      if (this.common.is_catalog_lunchbox) {
        return true
      }
    }

    else if (data == 'vendor-categories') {
      if (this.common.is_vendor_categories) {
        return true
      }
    }
    else if (data == 'tax-settings') {
      if (this.common.is_settings_tax) {
        return true
      }
    }
    else if (data == 'customer-groups') {
      if (this.common.is_customer_group) {
        return true
      }
    }
    else if (data == 'delivery-team') {
      if (this.common.is_deliveryTeams) {
        return true
      }
    }
    else if (data == 'delivery-salary') {
      if (this.common.is_delivery_boyCommition) {
        return true
      }
    }
    else if (data == 'delivery-salary') {
      if (this.common.is_delivery_boyCommition) {
        return true
      }
    }
    else{
      return true
    }
  }
  // this.permissions.forEach(element => {
  //   if (element.child && element.child.length > 0) {
  //     if (element.child.isChecked) {
  //     console.log(element.child.name, ' 1st child')
  //       x.push(element.child.name)
  //     }
  //     element.child.forEach(element2 => {
  //       if (element2.child && element2.child.length > 0) {
  //         element2.child.forEach(element3 => {
  //           if (element3.child && element3.child.length > 0) {
  //             element3.child.forEach(element4 => {
  //               if (element4.child && element4.child.length > 0) {

  //               } else {
  //                 if (element4.isChecked) x.push(element4.name)
  //               }
  //             });
  //           } else {
  //             if (element3.isChecked) x.push(element3.name)
  //           }
  //         });
  //       } else {
  //         if (element2.isChecked) x.push(element2.name)
  //       }
  //     });
  //   } else {
  //     if (element.isChecked) x.push(element.name)
  //   }
  // });

}