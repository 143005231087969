
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class OrderService {
    constructor(public api: ApiService) { }

    getOrders(page: number = 0, per_page: number = 0) {
        let endpoint = 'admin/orders/list/all' + page;
        return this
            .api
            .get(endpoint)
            .map(resp => resp.json());
    }
    getOrder(page: number = 0, per_page: number = 0,customer_id='',statusId='',
    vendor_id='',order_type='',from_date='',to_date='',from_amount='',
    to_amount='',order_id='') {
        
        let endpoint = 'admin/orders?';
        if (page > 0){
        endpoint += 'page=' + page 
        }
        if (per_page > 0){
        endpoint += '&per_page=' + per_page ;
        }
        if(statusId != '' && statusId !='scheduled'){
            endpoint+='&order_status='+statusId;
        }
        if(statusId != '' && statusId =='scheduled'){
          endpoint+='&schedule_type='+statusId;
      }
        if(customer_id != ''){
            endpoint+='&customer_id='+customer_id;
        }
        if(vendor_id != ''){
            endpoint+='&vendor_id='+vendor_id   ;
        }
        if(order_type != ''){
            endpoint+='&order_type='+order_type;
        }
        if(from_date != ''){
            endpoint+='&from_date='+from_date;
        }
        if(to_date != ''){
            endpoint+='&to_date='+to_date;
        }
        if(from_amount != ''){
            endpoint+='&from_amount='+from_amount;
        }
        if(to_amount != ''){
            endpoint+='&to_amount='+to_amount;
        }
        if(order_id != ''){
            endpoint+='&order_id='+order_id;
        }
        return this
            .api
            .get(endpoint)
            .map(resp => resp.json());
      }
      viewOrder(id) {
        let endpoint = 'admin/orders/' + id;
        return this
          .api
          .get(endpoint)
          .map(resp => resp.json());
      }


    

      // Order History
      addOrderHistory(data, isEdit) {
        let endpoint = 'admin/orders/history';
        if (isEdit) endpoint += '/' + data.id;
        if (isEdit) {
            return this
                .api
                .put(endpoint, data)
                .map(resp => resp.json());
        } else {
            return this
                .api
                .post(endpoint, data)
                .map(resp => resp.json());
        }
    }
    getOrderHistory(page: number = 0, per_page: number = 0) {
        let endpoint = 'admin/orders/history';
        if (page > 0)
            endpoint += '?page=' + page
        if (per_page > 0)
            endpoint += '&per_page=' + per_page;
        return this
            .api
            .get(endpoint)
            .map(resp => resp.json());
      }
      deleteOrderHistory(id) {
        let endpoint = 'admin/orders/history/' + id;
        return this
          .api
          .delete(endpoint)
          .map(resp => resp.json());
      }
      assignDeliveryBoy(data) {
        let endpoint = 'admin/orders/delivery-boy';
            return this
                .api
                .post(endpoint, data)
                .map(resp => resp.json());
    }

    orderReturn(data) {
        let endpoint = 'admin/orders/return/status';
        return this
        .api
        .post(endpoint, data)
        .map(resp => resp.json());
    }


    getOrderVendor(){
        let endpoint='admin/settings'
        return this
        .api
        .get(endpoint)
        .map(resp=>resp.json())
    }
}