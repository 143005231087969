import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class PromotionBannerService {

  constructor(public api: ApiService) { }
  getBanner(page: number = 0, per_page: number = 0) {
    let endpoint = 'admin/promotion-banners';
    if (page > 0)
      endpoint += '?page=' + page
    if (per_page > 0)
      endpoint += '&per_page=' + per_page;
    return this
      .api
      .get(endpoint)
      .map(resp => resp.json());
  }

  addBanner(data, isEdit) {
    let endpoint = 'admin/promotion-banners';
    if (isEdit) endpoint += '/' + data.id;
    let fData = new FormData();
    for (var key in data) {
        fData.append(key, data[key]);
    }
    if (isEdit) {
        return this
            .api
            .put(endpoint, data)
            .map(resp => resp.json());
    } else {
        return this
            .api
            .postAsFormData(endpoint, fData)
            .map(resp => resp.json());
    }

  }
  deleteBanner(id) {
    let endpoint = 'admin/promotion-banners/' + id;
    return this
      .api
      .delete(endpoint)
      .map(resp => resp.json());
  }
  viewBanner(id) {
    let endpoint = 'admin/promotion-banners/' + id;
    return this
      .api
      .get(endpoint)
      .map(resp => resp.json());
  }
  updateBanner(data) {
    let endpoint = 'admin/promotion-banners/image';
    let fData = new FormData();
    for (var key in data) {
        fData.append(key, data[key]);
    }
    return this
        .api
        .postAsFormData(endpoint, fData)
        .map(resp => resp.json());
}

}
