import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MatSnackBar } from '@angular/material';

import {   environmentKhaaz  as env } from 'environments/environment';


@Injectable()
export class CommonProvider {

    city_label = env.common_label.city_label;
    location_label = env.common_label.location_label;
    vendor_label_name = env.common_label.vendor_label_name;
    commission_module:boolean =env.common_label.commission_module;
    // use_wallet:boolean = 
    is_delivery_pickup:boolean=env.sub_menu.is_delivery_pickup
    delivery_status =env.common_label.delivery_status
    is_dashboard : boolean = env.menu.is_dashboard;
    coin_label=env.common_label.coin_label;
    cash_on_status=env.common_label.cash_on_status
    is_exicutieve_review:boolean=env.sub_menu.is_exicutieve_review
    is_projectName = env.project_details.appName;
    is_bulkProduct: boolean = env.common_label.is_bulkProduct;

    is_catalog : boolean = env.menu.is_catalog
    is_catalog_lunchbox:boolean = env.sub_menu.is_catalog_lunchbox
    location_view_label=env.common_label.location_view_label
    is_order : boolean = env.menu.is_order
    is_customer : boolean = env.menu.is_customer
    is_location : boolean = env.menu.is_location
    add_branch_instant_time=env.common_label.add_branch_instant_time
    add_branch_scheduled_time= env.common_label.add_branch_scheduled_time
    is_Schemes : boolean = env.menu.is_Schemes
    is_manufacture:boolean =env.sub_menu.is_manufacture
    product_is_current_stock :boolean=env.common_label.product_is_current_stock
    product_is_related_product:boolean =env.common_label.product_is_related_product
    dashbord_categories:boolean=env.common_label.order.dashbord_categories
    dashboard_products:boolean=env.common_label.order.dashboard_products
    notification_all_offers:boolean=env.common_label.notification_all_offers



    is_toAddress:boolean =env.common_label.order.is_toAddress
    is_billingAddress:boolean =env.common_label.order.is_billingAddress
    is_slug:boolean=env.common_label.is_slug

    is_pickup: boolean =env.menu.is_pickup
    is_addVendor:boolean=env.sub_menu.is_addVendor
    is_product_count:boolean=env.sub_menu.product_count


    is_deatails_care:boolean =env.common_label.is_deatails_care
    is_shipping_return: boolean =env.common_label.is_shipping_return
    is_size_fit: boolean =env.common_label.is_size_fit



    is_vendor : boolean = env.menu.is_vendor
    is_vendor_categories:boolean = env.sub_menu.is_vendor_categories
    is_BulkProduct:boolean= env.common_label.is_bulkProduct
    coin_rate:boolean= env.common_label.coin_rate
    is_promotion_banner : boolean = env.menu.is_promotion_banner
    is_admin_user : boolean = env.menu.is_admin_user

    is_offer : boolean = env.menu.is_offer
    is_coupon : boolean = env.sub_menu.is_coupon

    is_customer_membership : boolean = env.menu.is_customer_membership

    is_customer_group:boolean = env.sub_menu.is_customer_group
    enable_state_location:boolean =env.sub_menu.enable_state_location
    enabled_arabic:boolean = env.sub_menu.enabled_arabic
    product_calory:boolean = env.sub_menu.product_calory
    is_delivery_boy : boolean = env.menu.is_delivery_boy
    delivery_salary:false
    delivery_commission_type:false
    is_Password:boolean =env.common_label.is_Password
    is_Approve:boolean =env.common_label.is_Approve
    is_social_post =env.menu.is_social_post
    is_gift_template =env.menu.is_gift_template
    is_scheme:boolean =env.sub_menu.is_scheme
    is_social_media =env.menu.is_social_media
    is_notification= env.menu.is_notification
    is_Price =env.common_label.is_Price
    is_floatingCash =env.sub_menu.is_floatingCash
    is_delivery_boyTracking=env.sub_menu.is_delivery_boyTracking
    is_delivery_boyCommition=env.sub_menu.is_delivery_boycommition
    is_delivery_boyAttendance=env.sub_menu.is_delivery_boyattendance
    is_deliveryTeams=env.sub_menu.is_deliveryTeams
    is_branch_dashboard =env.menu.is_branch_dashboard
    is_custom_offer_push =env.sub_menu.is_custom_offer_push
    is_settings : boolean = env.menu.is_settings
    is_settings_tax:boolean = env.sub_menu.is_settings_tax
    is_settings_delivery:boolean = env.sub_menu.is_settings_delivery
    is_settings_spin:boolean = env.sub_menu.is_settings_spin
    is_settings_premium:boolean = env.sub_menu.is_settings_premium
    is_settings_settings:boolean = env.sub_menu.is_settings_settings
    is_schedule_order_interval: boolean = env.common_label.is_schedule_order_interval
    is_available_time: boolean = env.common_label.is_available_time
    is_product_rating: boolean = env.common_label.is_product_rating
    app_logo = env.project_details.logo
    app_icon = env.project_details.icon
    footer_name = env.project_details.footer_name
    is_credit = env.common_label.is_credit
    is_image_browser = env.common_label.is_image_browser
    









    BRANCH_ID="branch_id"
    

    constructor(private toastr: ToastrService, private _snackBar: MatSnackBar) { }
    processError(error) {
        if (typeof error == "object") {
            var jsonParsed = JSON.parse(error._body);
            if (jsonParsed.message) {
                this.showToastError(jsonParsed.message)
            } else if (jsonParsed) {
                var msg = '';
                console.log(jsonParsed)

                for (var prop in jsonParsed) {
                    console.log(jsonParsed[prop])
                    jsonParsed[prop].forEach(element => {
                        if (msg == '') {
                            msg += element;
                        } else {
                            msg += '</br>' + element;
                        }
                    });
                }

                this.showToastError(msg)
            } else {
                this.showToastError('Something went wrong')
            }
        }

    }



    // TOAST
    showToast(message?: string, title?: string) {
        this.toastr.show(message, title, { enableHtml: true })
    }
    showToastSuccess(message?: string, title?: string) {
        this.toastr.success(message, title, { enableHtml: true });
    }
    showToastError(message?: string, title?: string) {
        this.toastr.error(message, title, { enableHtml: true })
    }


    // SNACKBAR
    showSnackBar(msg) {
        this._snackBar.open(msg, '', { duration: 3000 });
    }
}