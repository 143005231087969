
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    constructor(public api: ApiService) { }
    // START TAX
    getTax(page: number = 0, per_page: number = 0) {
        let endpoint = 'admin/catalog/tax';
        if (page > 0)
            endpoint += '?page=' + page
        if (per_page > 0)
            endpoint += '&per_page=' + per_page;
        return this
            .api
            .get(endpoint)
            .map(resp => resp.json());
    }
    addTax(data, isEdit) {
        let endpoint = 'admin/catalog/tax';
        if (isEdit) endpoint += '/' + data.id;
        if (isEdit) {
            return this
                .api
                .put(endpoint, data)
                .map(resp => resp.json());
        } else {
            return this
                .api
                .post(endpoint, data)
                .map(resp => resp.json());
        }
    }
    deleteTax(id) {
        let endpoint = 'admin/catalog/tax/delete/' + id;
        return this
            .api
            .delete(endpoint)
            .map(resp => resp.json());
    }
    viewTax(id) {
        let endpoint = 'admin/catalog/tax/' + id;
        return this
          .api
          .get(endpoint)
          .map(resp => resp.json());
      }
    // TAX END
    getDeliveryCharge(page: number = 0, per_page: number = 0) {
        let endpoint = 'admin/delivery_charge';
        if (page > 0)
            endpoint += '?page=' + page
        if (per_page > 0)
            endpoint += '&per_page=' + per_page;
        return this
            .api
            .get(endpoint)
            .map(resp => resp.json());
    }
    addDeliveryCharge(data, isEdit) {
        let endpoint = 'admin/delivery_charge';
        if (isEdit) endpoint += '/' + data.id;
        if (isEdit) {
            return this
                .api
                .put(endpoint, data)
                .map(resp => resp.json());
        } else {
            return this
                .api
                .post(endpoint, data)
                .map(resp => resp.json());
        }
    }
    deleteDeliveryCharge(id) {
        let endpoint = 'admin/delivery_charge/delete/' + id;
        return this
            .api
            .delete(endpoint)
            .map(resp => resp.json());
    }

    addSpinWheelProduct(data, isEdit) {
        let endpoint = 'admin/spin_wheel';
        if (isEdit) endpoint += '/' + data.id;
        if (isEdit) {
            return this
                .api
                .put(endpoint, data)
                .map(resp => resp.json());
        } else {
            return this
                .api
                .post(endpoint, data)
                .map(resp => resp.json());
        }
    }

    getSpinWheelProduct(page: number = 0, per_page: number = 0) {
        let endpoint = 'admin/spin_wheel';
        if (page > 0)
            endpoint += '?page=' + page
        if (per_page > 0)
            endpoint += '&per_page=' + per_page;
        return this
            .api
            .get(endpoint)
            .map(resp => resp.json());
    }

    deleteSpinWheelProduct(id) {
        let endpoint = 'admin/spin_wheel/delete/' + id;
        return this
            .api
            .delete(endpoint)
            .map(resp => resp.json());
    }
    addPremiumProduct(data, isEdit) {
        let endpoint = 'admin/premium-products';
        if (isEdit) endpoint += '/' + data.id;
        if (isEdit) {
            return this
                .api
                .put(endpoint, data)
                .map(resp => resp.json());
        } else {
            return this
                .api
                .post(endpoint, data)
                .map(resp => resp.json());
        }
    }

    getPremiumProduct(page: number = 0, per_page: number = 0) {
        let endpoint = 'admin/premium-products';
        if (page > 0)
            endpoint += '?page=' + page
        if (per_page > 0)
            endpoint += '&per_page=' + per_page;
        return this
            .api
            .get(endpoint)
            .map(resp => resp.json());
    }

    deletePremiumProduct(id) {
        let endpoint = 'admin/premium-products/' + id;
        return this
            .api
            .delete(endpoint)
            .map(resp => resp.json());
    }
    addSettings(data) {
        let endpoint = 'admin/settings';
            return this
                .api
                .put(endpoint, data)
                .map(resp => resp.json());
    }
    getSettings() {
        let endpoint = 'admin/settings';
            return this
                .api
                .get(endpoint)
                .map(resp => resp.json());
    }
}
