import { MessagingService } from './providers/messaging.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserData } from './providers/user-data';
import { Title } from '@angular/platform-browser';
import { global } from './app.global'
import { MatDialog } from '@angular/material';
import { filter } from 'rxjs/operators';
import { Router, Event as RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router'
import { NgZone, Renderer, ElementRef, ViewChild } from '@angular/core'
import { SettingsService } from './providers/settings.service';
import { CommonProvider } from './providers/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  @ViewChild('spinnerElement')
  spinnerElement: ElementRef
  loading = false
  message:any;
  settingsDetails:any=[]
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private user: UserData, private titleService: Title,
    private dialogRef: MatDialog, private ngZone: NgZone, private renderer: Renderer,
    public messagingService:MessagingService,public settings:SettingsService,public common:CommonProvider) {

    router.events.subscribe((event: RouterEvent) => {
      this._navigationInterceptor(event)
    })

    this.router.events
      .subscribe(() => {
        this.dialogRef.closeAll();
      });

  }

  private _navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.ngZone.runOutsideAngular(() => {
        this.renderer.setElementStyle(
          this.spinnerElement.nativeElement,
          'opacity',
          '1'
         
        )
      })
    }
    if (event instanceof NavigationEnd) {
      this._hideSpinner()
    }
    if (event instanceof NavigationCancel) {
      this._hideSpinner()
    }
    if (event instanceof NavigationError) {
      this._hideSpinner()
    }
  }

  private _hideSpinner(): void {
    this.ngZone.runOutsideAngular(() => {
      this.renderer.setElementStyle(
        this.spinnerElement.nativeElement,
        'opacity',
        '0'
      )
    })
  }
  ngOnInit() {
    this.loadSettings()
    const userId = 'user001';
    this.messagingService.requestPermission(userId)
    this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage


    this.user.loadSession()
    // this.router.events
    //   .filter((event) => event instanceof NavigationEnd)
    //   .map(() => this.router)
    //   .subscribe((event) => {


    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {

      const title = this.getTitle(this.router.routerState, this.router.routerState.root).join(global.titleSperator);
      this.titleService.setTitle(global.appTitle + (title ? global.titleSperator + title : ''));
    }
    );


  }

  loadSettings() {
    this.settings.getSettings().subscribe(response => {
      if (response.success == true) {
        this.settingsDetails = []
        this.settingsDetails = response.data;
        if(response.data.product_code_prefix){
         localStorage.setItem('catCode',response.data.product_code_prefix)
        }
        
      } 
    }, err => {
      // this.user.authorizedError(err)
    });
  }

  getTitle(state, parent) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

}
