import { Injectable, Inject } from '@angular/core';
import { StorageService, LOCAL_STORAGE } from 'ngx-webstorage-service';
import { Router } from '@angular/router';
import { EventService } from './event.service';

@Injectable()
export class UserData {
    public profile: any = {}
    public apiKey: string = ''
    public isLoggedIn: any;
    HAS_LOGGED_IN = 'hasLoggedIn';
    APIKEY = 'apiKey';
    PROFILE = 'userProfile';

    constructor(@Inject(LOCAL_STORAGE) private storage: StorageService, public router: Router,
        private event: EventService) {
        this.event.configObservable.subscribe(value => {
            if ([this.event.USER_LOGIN].includes(value)) {
                this.loadSession()
            }
        })
    }

    updateLoginData(res) {
        this.profile = res.data;
        this.apiKey = res.api_token;
        sessionStorage.setItem(this.APIKEY, this.apiKey);
        this.storage.set(this.APIKEY, this.apiKey);
        this.storage.set(this.PROFILE, this.profile);
        this.storage.set(this.APIKEY, this.apiKey);
        this.storage.set(this.HAS_LOGGED_IN, true);
    }
    logout(): void {
        sessionStorage.removeItem(this.APIKEY);
        this.storage.remove(this.APIKEY);
        this.storage.remove(this.HAS_LOGGED_IN);
        this.storage.remove(this.APIKEY);
        this.storage.remove(this.PROFILE);
        this.apiKey = null;
        this.router.navigate(['/login'])
    };
    loadSession() {
        if (this.storage.has(this.HAS_LOGGED_IN) && this.storage.get(this.APIKEY)) {
            this.isLoggedIn = this.storage.get(this.HAS_LOGGED_IN);
            this.apiKey = this.storage.get(this.APIKEY);
            this.profile=this.storage.get(this.PROFILE);
        } else {
            this.router.navigate(['/login'])
        }
    }
    authorizedError(err){
        console.log(err)
        if(err.status == 401 && err.statusText == "Unauthorized"){
        this.router.navigate(['/login']);
        }
    }
}
