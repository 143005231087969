import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs'
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { Profile } from './profile.service';

@Injectable()
export class MessagingService {

  alertSound:any=''
  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging,
    private router:Router,
    private profile:Profile) {
    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
  }

  /**
   * update token in firebase database
   * 
   * @param userId userId as a key 
   * @param token token as a value
   */
  updateToken(userId, token) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        const data = {};
        data[userId] = token
        this.angularFireDB.object('fcmTokens/').update(data)
      })
  }

  /**
   * request permission for notification from firebase cloud messaging
   * 
   * @param userId userId
   */
  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
        this.registerFCM(token)
        this.updateToken(userId, token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  registerFCM(_token) {
    this.profile.registerFcm({ fcm_token: _token, device_type: 'web' }).subscribe(response => {
      console.log(response)
      if (response.success == true) {
        console.log('FCM Registered');
      }
    }, err => {
      console.log('Something went wrong');
      console.log(err)
    });
}

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log("new message received. ", payload);
        this.currentMessage.next(payload);
        this.sweetAlert(payload)
      })
  }

  sweetAlert(payload){
    this.alertSound = new Audio('assets/tunes/Midnight-chimes-sound-effect.mp3')
    this.alertSound.addEventListener('ended', function() {
      this.play();
    }, false);
    this.alertSound.play();
     swal({
      title: payload.notification.title,
      text: payload.notification.body,
      type: 'warning',
      confirmButtonText: "View",
      showCancelButton: true,
    })
    .then((willDelete) => {
        if(willDelete.value){
          this.router.navigate(['/order/view/',payload.data.item_id])
          this.alertSound.pause()
        }else{
          this.alertSound.pause()
        }
    });
  }
}