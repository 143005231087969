import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersListComponent } from './users-list/users-list.component';
import { RouterModule, Routes } from '@angular/router';
import { UserViewComponent } from './user-view/user-view.component';
import { AddUserComponent } from './add-user/add-user.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RoleGroupComponent } from './role-group/role-group.component';
import { MatSidenavModule, MatFormFieldModule, MatTableModule, MatPaginatorModule, MatInputModule, MatListModule, MatDialogModule, MatTabsModule, MatTreeModule, MatIconModule, MatSlideToggleModule, MatCheckboxModule, MatSortModule } from '@angular/material';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AddRoleGroupComponent } from './add-role-group/add-role-group.component';
import {PermissionModule} from '../../permission.module'
import { ClickOutsideModule } from 'ng4-click-outside';
import { RoleGroupViewComponent } from './role-group-view/role-group-view.component';

const ROUTES: Routes = [
  { path: '', redirectTo: 'list', pathMatch: 'full' },
  { path: 'list', component: UsersListComponent, data: { title: 'Users List' } },
  { path: 'role-group', component: RoleGroupComponent, data: { title: 'Role Group' } },
  { path: 'add-role', component: AddRoleGroupComponent, data: { title: 'Add Role' } },
  { path: 'role-group-view', component:RoleGroupViewComponent, data: { title: 'role-roup-view' } },
]

@NgModule({
  declarations: [
    UsersListComponent,
    UserViewComponent,
    AddUserComponent,
    RoleGroupComponent,
    AddRoleGroupComponent,
    RoleGroupViewComponent
  ],
  entryComponents: [
    AddUserComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatTableModule,
    MatPaginatorModule,
    MatInputModule,
    MatDialogModule,
    MatListModule,
    RouterModule.forChild(ROUTES),
    ImageCropperModule,
    MatTreeModule,
    MatIconModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    PermissionModule,
    ClickOutsideModule,
    MatSortModule
  ]
})
export class UsersManagementModule { }
