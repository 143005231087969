import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material';
import { Router } from '@angular/router';
import { CatalogService } from 'app/providers/catalog.service';
import { CommonProvider } from 'app/providers/common';
import { ProductService } from 'app/providers/product.service';
import { VendorService } from 'app/providers/vendor.service';
import * as Chart from 'chart.js';
import { ChartOptions, ChartType } from 'chart.js';
import * as moment from 'moment';
import { Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip, SingleDataSet } from 'ng2-charts';
import { MatSelectSearchVersion } from 'ngx-mat-select-search';
import { SettingsService } from '../../../providers/settings.service';

@Component({
  selector: 'app-dashboard-branch',
  templateUrl: './dashboard-branch.component.html',
  styleUrls: ['./dashboard-branch.component.css']
})
export class DashboardBranchComponent implements OnInit {
  @ViewChild('barChart') barChart: ElementRef;
  public vendorCtrl: FormControl = new FormControl();
  public vendorFilterCtrl: FormControl = new FormControl();
  @ViewChild('singleSelect') singleSelect: MatSelect;
  matSelectSearchVersion = MatSelectSearchVersion;
   public pieChartOptions: ChartOptions = {
    responsive: true,
  };



  isEmptyDelivery: boolean = false
  type = 'yearly'
  report: any = []
  amount: any = []
  bars: any = []
  isNext: boolean = false
  isEmptyProduct: boolean = false
  dates: any = []
  productTodayDate: any = moment()
  isProductReportLoading: boolean = false
  public pieChartPlugins = [];
  pieData: number = 0
  productReport: any = []
  vendorDetails: any = []
  public pieChartLabelsProducts: string[] = [];
  public pieChartDataProducts: number[] = [];
  public pieChartLabelsDelivery: string[] = [];
  public pieChartDataDelivery: number[] = [];
  public pieChartType: string = 'pie';
  public doughnutChartOptions: any = { datalabels: { formatter: 'percentage', color: '#000' }, }
  public ChartColors: any[] = [
    {
      backgroundColor: ["#9E120E", "#FF5800", "#FFB414", "#DC143C", '#FF69B4', '#3CB371',
        '#20B2AA', '#B8860B', '#2F4F4F', '#778899', '#FF69B4', '#98FB98']
    },
  ];
  public ChartDeliveryColors: any[] = [
    { backgroundColor: ["green", "blue", "orange", "red", 'red', 'red', 'red',] },
  ];
  todayDate = new Date()
  details: any;
  per_page: any = 12
  myDataSets: any = []
  currentLabel: any
  previousLabel: any


  constructor(public vendor: VendorService, 
    public catalog: CatalogService, 
    public common: CommonProvider, public router: Router, 
    private settings:SettingsService, 
    private ventor: VendorService,
    public product: ProductService) {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnInit() {
    this.loadVendors()
    this.loadDetails()
    this.loadProductReport()
  }

  getValue(evt, id){
    console.log("evt" , evt.target.checked)

    var data = {
      completely_closed : evt.target.checked ? 1 :0,
    }
      this.ventor.updateShowVentor(data, id).subscribe(response => {



        console.log('response' , response)


        // if (response.success == true) {
        //   console.log("response" , response)
        //   // this.dialogRef.close({ success: true, data: response })
        // } 
      }, err => {
        console.log('ERRRRROR')
        console.log(err)
        this.common.processError(err)
      });
  }

  loadVendors() {
    this.vendor.getVendors(1, 100).subscribe(response => {
      if (response.success == true) {
        this.vendorDetails = response.data.data;
      }
    }, err => {
    });
  }


  loadDetails() {
    this.catalog.getDetails().subscribe(response => {
      if (response.success == true) {
        console.log(response)
        this.details = []
        this.details = response.data
        console.log(this.details)
      }
    }, err => {
      // this.user.authorizedError(err)
    });
  }


  isShopProgess(date) {
    var startTime: any
    var endTime: any;
    var format = 'HH:mm'
    var isValidTime: Boolean = false
    var currentTime = moment(new Date(), format)
    let day = moment(this.todayDate).format('ddd')
    day = day.toLowerCase()
    if (date != null || date != undefined) {
      date[day].forEach(element => {
        startTime = moment(element.from, "hh:mm")
        endTime = moment(element.to, "hh:mm")

        if (currentTime.isBetween(startTime, endTime)) {
          isValidTime = true
        } else {
          isValidTime = false
        }
      });
    } else {
      isValidTime = false
    }
    return isValidTime
  }

  openBranchOrder(id) {
    localStorage.setItem(this.common.BRANCH_ID, id)
    this.router.navigate(['/dashboard'])
  }

  searchVendor(event) {
    this.vendor.searchVendor(event.target.value, 1, 100).subscribe(resp => {
      if (resp.success = true) {
        console.log(resp)
        this.vendorDetails = resp.data.data;
      }
    })
  }

  loadProductReport() {
    this.pieData = 0
    let reportDetails: any = []
    this.isProductReportLoading = true
    var date = moment(this.productTodayDate).format("DD-MM-YYYY")
    this.product.getProductReport(date, this.type, this.per_page).subscribe(res => {
      if (res.success) {
        this.productReport = res.data
        this.report = res.data.trend
        this.report.forEach(element => {
          var obj = {
            name: element.title
          }
          this.amount = []
          this.dates = []
          element.data.forEach(element1 => {
            if (this.type == 'weekly') this.dates.push(moment(element1.date).format('ddd'))
            if (this.type == 'monthly') this.dates.push(moment(element1.date).format('DD'))
            if (this.type == 'yearly') this.dates.push(moment(element1.date).format('MMM'))
            this.amount.push(element1.total_amount)
          })
          reportDetails.push({ label: obj.name, data: this.amount, labels: this.dates })
        })
        this.myDataSets = reportDetails
        this.currentLabel = reportDetails[0].label
        this.previousLabel = reportDetails[1].label
        this.createBarChart()
        let products: any = []
        let productCount: any = []
        let title: any = []
        let count: any = []
        this.productReport.products.forEach(prod => {
          products.push(prod.product_name)
          productCount.push(prod.product_count)
        });
        console.log(products)
        this.pieChartLabelsProducts = products;
        this.pieChartDataProducts = productCount;
        if (this.productReport && this.productReport.delivery_time) {
          this.productReport.delivery_time.forEach(del => {
            this.pieData = this.pieData + del.count
            title.push(del.title)
            count.push(del.count)
          });
          this.pieChartLabelsDelivery = title;
          this.pieChartDataDelivery = count;
        }

        if (!this.productReport.products || this.productReport.products.length < 1) {
          this.isEmptyProduct = true
        } else {
          this.isEmptyProduct = false
        }
        if (!this.productReport.delivery_time || this.productReport.delivery_time.length < 1) {
          this.isEmptyDelivery = true
        } else {
          this.isEmptyDelivery = false
        }
      }
      this.isProductReportLoading = false
    }, err => {
      this.isProductReportLoading = false
    });
  }

  createBarChart() {
    console.log(this.myDataSets[1])
    this.bars = new Chart(this.barChart.nativeElement, {
      type: 'line',
      data: {
        labels: this.myDataSets[0].labels,
        datasets: [{
          label: this.myDataSets[0].label,
          data: this.myDataSets[0].data,
          backgroundColor: "#383a3e",
          borderColor: "#ffb414",
          // pointBorderColor: "rgb(92,213,205)",
          pointBackgroundColor: "#fffcf3",
          borderCapStyle: "butt",
          borderWidth: 1
        },
        {
          label: this.myDataSets[1].label,
          data: this.myDataSets[1].data,
          backgroundColor: "#22683c",
          borderColor: "#9e120e",
          pointBackgroundColor: "#fffcf3",
          borderCapStyle: "butt",
          borderWidth: 1
        },
        ]
      },
      options: {
        legend: {
          labels: {
            fontColor: '#808080'
          },
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              fontColor: '#808080'
            },
          }],
          xAxes: [{
            ticks: {
              fontColor: '#808080'
            },
          }]
        }
      }
    });
  }


  getTimePerid(event) {
    console.log(event.target.value)
    this.isNext = true
    this.isEmptyProduct = false
    this.isEmptyDelivery = false
    var currentDate = moment()
    this.productTodayDate = currentDate
    if (event.target.value == 'daily') {
      this.type = 'daily'
    } else if (event.target.value == 'weekly') {
      this.type = 'weekly'
    }
    else if (event.target.value == 'monthly') {
      this.type = 'monthly'
    } else if (event.target.value == 'yearly') {
      this.type = 'yearly'
    }
    this.loadProductReport()
  }
}
