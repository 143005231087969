import { Component } from '@angular/core';
import { smoothlyMenu } from '../../../app.helpers';
import { UserData } from 'app/providers/user-data';
declare var jQuery: any;

@Component({
  selector: 'topnavbar',
  templateUrl: 'topnavbar.template.html'
})
export class TopNavbarComponent {
  constructor(public user: UserData) { }

  toggleNavigation(): void {
    jQuery("body").toggleClass("mini-navbar");
    smoothlyMenu();
  }
  logout() {
    this.user.logout()
    localStorage.clear()
  }
}
