import { Component, OnDestroy, OnInit, ɵConsole, } from '@angular/core';
import { CatalogService } from 'app/providers/catalog.service';
import { OrderService } from 'app/providers/orderService';
import { Router } from '@angular/router';
import { UserData } from 'app/providers/user-data';
import { CommonProvider } from 'app/providers/common';

@Component({
  selector: 'starter',
  templateUrl: 'starter.template.html',
  styleUrls: ['starter.template.css']
})
export class StarterViewComponent implements OnDestroy, OnInit  {

  page:any=1
  per_page:any=20
  total:number=0
  details:any=[]
  orderDetails:any=[]
  orderTypeId:any=''
  customerId:any=''
  vendorId:any;
public nav:any;

public constructor(public catalog:CatalogService,private order:OrderService,
  public router:Router,public user:UserData,public common:CommonProvider) {
    if(localStorage.getItem(this.common.BRANCH_ID) && this.common.is_branch_dashboard){
      this.vendorId=localStorage.getItem(this.common.BRANCH_ID)
    }
  this.loadDetails()
  this.loadOrder()
  this.nav = document.querySelector('nav.navbar');
}

loadDetails() {
  this.catalog.getDetails(this.vendorId).subscribe(response => {
    if (response.success == true) {
      console.log(response)
      this.details = []
      this.details = response.data
    }
  }, err => {
    this.user.authorizedError(err)
  });
} 


loadOrder() {
  this.order.getOrder(this.page, this.per_page,this.customerId,this.orderTypeId,this.vendorId).subscribe(response => {
    console.log(response)
    if (response.success == true) {
      this.total = response.data.total;
      this.orderDetails = response.data.data;
      console.log(this.orderDetails)
    } 
  }, err => {
    this.user.authorizedError(err)
  });
}

public ngOnInit():any {
  this.nav.className += " white-bg";
}


public ngOnDestroy():any {
  this.nav.classList.remove("white-bg");
}

orders(value){
  console.log(value)
  localStorage.setItem('orderStatus',value)
  this.router.navigate(['/order/list/'])
}
onClickCategory(){
  this.router.navigate(['/catalog/category/list'])
}

onClickProduct(){
  this.router.navigate(['/catalog/product/list'])
}


getTwoDigit(value){
  if(value<10){
    return "0"+value
  }else{
    return value;
  }
}
}
