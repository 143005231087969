import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";

import { StarterViewComponent } from "./starterview.component";
import { LoginComponent } from "./login/login.component";

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProfileComponent } from './profile/profile.component';
import { MatFormFieldModule, MatSelectModule } from '@angular/material';
import { DashboardBranchComponent } from "./dashboard-branch/dashboard-branch.component";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { ChartsModule } from 'ng2-charts';

@NgModule({
  declarations: [
    StarterViewComponent,
    LoginComponent,
    ProfileComponent,
    DashboardBranchComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    MatSelectModule,
    ChartsModule
  ],
  exports: [
    StarterViewComponent,
    DashboardBranchComponent,
    LoginComponent,
    ProfileComponent
  ],
})

export class AppviewsModule {
}
