import { Component } from '@angular/core';
import { Router } from '@angular/router';
import 'jquery-slimscroll';
import { global } from './../../../app.global';
import { UserData } from 'app/providers/user-data';
import { smoothlyMenu } from '../../../app.helpers';
import { CommonProvider } from '../../../providers/common';

declare var jQuery: any;

@Component({
  selector: 'navigation',
  templateUrl: 'navigation.template.html'
})


export class NavigationComponent {
  app_name: any;
  roles: any = []

  menus: any = [
    {
      name: 'Dashboard',isEnable:this.common.is_dashboard, route: 'dashboard', appPermissions: 'catalog', icon:"fa-book",
      submenu: [
        { name: 'Dashboard', route: 'view', appPermissions: 'product' },
      ]
    },
    {
      name: 'Orders',isEnable:this.common.is_order, route: 'order', appPermissions: 'orders', icon:"fa-cart-plus",
      submenu: [
        { name: 'Order List', route: 'list', appPermissions: 'orders' },
      ]
    },

   
    
    {
      name: 'Catalogue',isEnable:this.common.is_catalog, route: 'catalog', appPermissions: 'catalog', icon:"fa-book",
      submenu: [
        { name: 'Products', route: 'product/list', appPermissions: 'product' },
        { name: 'Categories', route: 'category/list', appPermissions: 'catalog-categories' },
        // { name: 'Variants', route: 'variants/list', appPermissions: 'options' },
        { name: 'Lunch Box',isHide:!this.common.is_catalog_lunchbox, route: 'lunch-box/list', appPermissions: 'lunchbox-institute' },
        { name: 'Manufactures/Brand',isHide:!this.common.is_manufacture, route: 'manufacturers/list', appPermissions: 'manufacturers' },
        { name: 'Product Units', route: 'product-unit/list', appPermissions: 'product-unit' },
      ]
    },
    {
      name: 'Customers',isEnable:this.common.is_customer, route: 'customer', appPermissions: 'customers', icon:"fa-users",
      submenu: [
        { name: 'Customer Group',isHide:!this.common.is_customer_group, route: 'customer-group/list', appPermissions: 'customer-groups' },
        { name: 'Customer List', route: 'customer/list', appPermissions: 'customer' },
        // { name: 'Favourite', route: 'user' },
      ]
    },
    {
      name: 'Pick Up Points',isEnable:this.common.is_pickup, route: 'pickup', appPermissions: 'membership', icon:"fa fa-truck",
      submenu: [
        { name: 'Pick Up List', route: 'pickuppoint/list', appPermissions: 'membership' }
        // { name: 'Favourite', route: 'user' },
      ]
    },
    {
      name: 'Locations',isEnable:this.common.is_location, route: 'location', appPermissions: 'locations', icon:"fa-map-marker",
      submenu: [
        { name: this.common.city_label, route: 'city/list', appPermissions: 'city' },
        { name:'Available Pincode', route: 'available-pincode/list', appPermissions: 'city' },

      ]
    },
    {
      name: this.common.vendor_label_name,isEnable:this.common.is_vendor, route: 'vendor', appPermissions: 'vendors', icon:"fa-venus-mars",
      submenu: [
        { name: this.common.vendor_label_name, route: 'list', appPermissions: 'vendor' },
        { name: this.common.vendor_label_name + 'Category',isHide:!this.common.is_vendor_categories, route: 'categories', appPermissions: 'vendor-categories' },
      ]
    },
    {
      name: 'Promotion Banners',isEnable:this.common.is_promotion_banner, route: 'promotion-banners', appPermissions: 'promotional-banners', icon:"fa-volume-off",
      submenu: [
        { name: 'Banners List', route: 'banners/list', appPermissions: 'promotional-banners' },
      ]
    },
    {
      name: 'Social Media',isEnable:this.common.is_social_media, route: 'social-media', appPermissions: 'promotional-banners', icon:"fa fa-share-square-o",
      submenu: [
        { name: 'List', route: 'list', appPermissions: 'promotional-banners' },
      ]
    },
    {
      name: 'Notification',isEnable:this.common.is_notification, route: 'notification', appPermissions: 'promotional-banners', icon:"fa fa-bell",
      submenu: [
        { name: 'List', route: 'not-list', appPermissions: 'promotional-banners' },
      ]
    },


    {
      name: 'Schemes',isEnable:this.common.is_Schemes, route: 'Schemes', appPermissions: 'membership', icon:"fa fa-tasks",
      submenu: [
        { name: 'Scheme list', route: 'Scheme list', appPermissions: 'membership' },
      ]
    },
    {
      name: 'Notifications',
      submenu: [
        { name: 'New' },
        { name: 'List' },
      ]
    },
    {
      name: 'Offers',isEnable:this.common.is_offer, route: 'offers',appPermissions: 'offers', icon:"fa-gift",
      submenu: [
        { name: 'Offer List', route: 'list',appPermissions: 'offers' },
        { name: 'Coupon List',isHide:!this.common.is_coupon, route: 'coupon/list',appPermissions: 'offers' },
      ]
    },

    {
      name: 'Gift template',isEnable:this.common.is_order, route: 'gift-template', appPermissions: 'orders', icon:"fa-cart-plus",
      submenu: [
        { name: 'Gift template List', route: 'list', appPermissions: 'orders' },
        { name: 'Categories', route: 'categories', appPermissions: 'orders' },
      ]
    },

    {
      name: 'Gallery',isEnable:this.common.is_order, route: 'gallery', appPermissions: 'orders', icon:"fa-cart-plus",
      submenu: [
        { name: 'Gallery List', route: 'list', appPermissions: 'orders' },
      ]
    },

    {
      name: 'Customer Membership',isEnable:this.common.is_customer_membership, route: 'membership', appPermissions: 'membership', icon:"fa-id-card-o",
      submenu: [
        { name: 'MemberShip Plans', route: 'plans/list', appPermissions: 'membership' },
        { name: 'Plan Products', route: 'plan-product/list', appPermissions: 'membership' },
      ]
    },
   
    {
      name: 'Delivery Boy Management',isEnable:this.common.is_delivery_boy, route: 'delivery-boy-management', appPermissions: 'delivery-management', icon:"fa-id-badge",
      submenu: [
        { name: 'Delivery Boys',isEnable:this.common.is_delivery_boy, route: 'delivery-boys/list', appPermissions: 'delivery-boy' },
        { name: 'Delivery Teams',isHide:!this.common.is_deliveryTeams, route: 'delivery-teams/list', appPermissions: 'delivery-team' },
        { name: 'Commissions',isHide:!this.common.is_delivery_boyCommition, route: 'commission/list', appPermissions: 'delivery-salary' },
        { name: 'Attendance',isHide:!this.common.is_delivery_boyAttendance, route: 'attendance/list', appPermissions: 'delivery-salary' },
        { name: 'Floating Cash',isHide:!this.common.is_floatingCash, route: 'deliveryboy/floating-cash', appPermissions: 'delivery-boy' },
        { name: 'Delivery Boy Tracking',isHide:!this.common.is_delivery_boyTracking, route: 'deliveryboy/tracking', appPermissions: 'delivery-salary' }
      ]
    },


    {
      name: 'Admin Users',isEnable:this.common.is_admin_user, route: 'user', appPermissions: 'users-and-roles', icon:"fa-user-circle-o",
      submenu: [
        { name: 'Users', route: 'list', appPermissions: 'users' },
        { name: 'Role Group', route: 'role-group', appPermissions: 'roles' },
      ]
    },
    {
      name: 'Settings',isEnable:this.common.is_settings, route: 'settings', appPermissions: 'settings', icon:"fa-cog",
      submenu: [
        { name: 'Tax List',isHide:!this.common.is_settings_tax, route: 'tax/list', appPermissions: 'tax-settings' },
        { name: 'Delivery Charges',isHide:!this.common.is_settings_delivery, route: 'delivery-charges/list', appPermissions: 'delivery-boy' },
        { name: 'Spin Wheel',isHide:!this.common.is_settings_spin, route: 'spin-wheel/list', appPermissions: 'tax-settings' },
        { name: 'Premium Products',isHide:!this.common.is_settings_premium, route: 'premium-product/list', appPermissions: 'tax-settings' },
        { name: 'Settings',isHide:!this.common.is_settings_settings, route: 'common-settings/list', appPermissions: 'tax-settings' },
      ]
    },
    {
      name: 'Login History', route: 'login-history', appPermissions: '',
      submenu: [
        { name: 'List', route: 'list', appPermissions: '' },
      ]
    },

  ]

  constructor(private router: Router, public user: UserData,private common:CommonProvider) {
    this.app_name = global.appTitle;
    this.roles.push(localStorage.getItem('role').split(','))

    jQuery(document).on("click", function(event){
      var $trigger = jQuery(".icon-close");
      var $trigger2 = jQuery(".nav-close");
      
      if($trigger !== event.target && !$trigger.has(event.target).length && $trigger2 !== event.target && !$trigger2.has(event.target).length){
        jQuery("body").removeClass("mini-navbar");
      }            
  });
  }


  permission(name: string) {
   let isPermission:boolean = false
    this.menus.forEach(element => {
       if(name == element.name){
         element.submenu.forEach(element1=>{
           if(this.roles[0].find(x=>x==element1.appPermissions)){
            isPermission =true;
            return isPermission
           }
        })
       }
    });
    return isPermission
  }


  ngAfterViewInit() {
    jQuery('#side-menu').metisMenu();
    if (jQuery("body").hasClass('fixed-sidebar')) {
      jQuery('.sidebar-collapse').slimscroll({
        height: '100%'
      })
    }
  }

  toggleNavigation(): void {
    if (jQuery(document).width() < 769) {
      jQuery("body").toggleClass("mini-navbar");
    smoothlyMenu();
    }
      
  }

  activeRoute(routename: string): boolean {
    return this.router.url.indexOf(routename) > -1;
  }
  logout() {
    localStorage.clear()
    this.user.logout()
  }

}
