import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CommonProvider } from 'app/providers/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from 'app/_helpers/must-match.validator';
import { Validator } from 'app/_helpers/validators';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Profile } from 'app/providers/profile.service';
import { ImageHelperService } from 'app/_helpers/imageHelper.service';
import { VendorService } from 'app/providers/vendor.service';
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
  addUserForm: FormGroup;
  submitted = false;
  isVendor: boolean = false
  validator = new Validator;
  vendorSelected = 'null'
  roleGroups: any = []
  vendorDetails: any = []
  isEdit: boolean = false
  changePassword: boolean = false
  userDetails: any = []
  imageChangedEvent: any = '';
  imageSize: any = ''
  croppedImage: any = '';
  venderLabel:any=this.common.vendor_label_name;
  constructor(public dialogRef: MatDialogRef<AddUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, private imageHelper: ImageHelperService,
    public common: CommonProvider, public profile: Profile, private vendor: VendorService) {
    this.userDetails = data.userDetails
    if (data.userDetails != null) this.isEdit = true;
  }

  ngOnInit() {

  // jQuery.get("https://graph.instagram.com/me/media?fields=id,media_type,media_url,username,timestamp,comments_count,like_count&access_token=IGQVJYMGY5WW04Tmhmd29uNDRGQU8tdW5BV282NlZAScU5MVFZAhWGkwU0x4b0ZA4VTNCdXZANb0p0TVpZAZAjl5QWxpSkVTX1V1MWc1VW13anNwSG1JN3hIenVRTDJsUlV2cXduUmxuMll3", function(data, status){
  // console.log('+++++++++++++++++++++++++++++++++++++++++++')  
  // console.log(data)
  //   });

    
    if (!this.isEdit) {
      this.changePassword = true
      this.addUserForm = this.formBuilder.group({
        name: ['', Validators.required],
        username: ['', [Validators.required, Validators.pattern(this.validator.emailPattern())]],
        password: ['', [Validators.required, Validators.minLength(6)]],
        // password_confirmation: ['', [Validators.required]],
        mobile: ['', [Validators.required, Validators.pattern(this.validator.numberPattern()), Validators.minLength(10), Validators.maxLength(10)]],
        role: ['', Validators.required],
        vendor_id: [''],
        profile_pic: [],
      }
      // , {
      //   validator: MustMatch('password', 'password_confirmation')
      // }
      );
    } else {
      this.changePassword = false
      this.addUserForm = this.formBuilder.group({
        name: ['', Validators.required],
        username: [''],
        password: ['', [Validators.minLength(6)]],
        // password_confirmation: [''],
        mobile: ['', [Validators.required, Validators.pattern(this.validator.numberPattern()), Validators.minLength(10), Validators.maxLength(10)]],
        role: ['', Validators.required],
        vendor_id: [''],
      },
      // {
      //   validator: MustMatch('password', 'password_confirmation')
      // }
      );
    }
    this.loadRoleGroups()
    this.loadVendors()

    if (this.isEdit) {
      this.patchOfferValue()
      this.addUserForm.get('name').patchValue(this.userDetails.name);
      this.addUserForm.get('username').patchValue(this.userDetails.username);
      this.addUserForm.get('mobile').patchValue(this.userDetails.mobile);
      this.addUserForm.get('role').patchValue(this.userDetails.role_ids[0]);
      this.addUserForm.get('vendor_id').patchValue(this.userDetails.vendor_id);
    }


  }
  get auf() { return this.addUserForm.controls; }

  patchOfferValue() {
    if (this.userDetails.vendor_id) {
      this.vendorSelected = 'selected'
      this.isVendor = true
    }
  }
  checkboxEvent(event) {
    this.isVendor = false
    if (event.checked == true) {
      this.isVendor = true
    }
  }

  loadRoleGroups() {
    this.profile.getRoleGroups().subscribe(response => {
      if (response.success == true) {
        this.roleGroups = response.data.data;
        console.log(this.roleGroups)
      }
    }, err => {
    });
  }
  loadVendors() {
    this.vendor.getVendors(1,100).subscribe(response => {
      if (response.success == true) {
        this.vendorDetails = response.data.data;
        console.log(this.vendorDetails)
      } else {
      }
    }, err => {
    });
  }
  onAddUser() {
    console.log("hi")
    this.submitted = true;
    if (this.addUserForm.valid) {
      var data = {
        name: this.auf.name.value,
        username: this.auf.username.value,
        password: this.auf.password.value,
        password_confirmation: this.auf.password.value,
        mobile: this.auf.mobile.value,
        role: this.auf.role.value,
        vendor_id: this.auf.vendor_id.value,
      };
      if (this.croppedImage && this.croppedImage != '') data['profile_pic'] = this.imageHelper.base64ToImageFile(this.croppedImage)
      if (this.isEdit) data['id'] = this.userDetails._id;
      console.log(data)
      this.profile.addUser(data, this.isEdit).subscribe(response => {
        console.log(response)
        if (response.success == true) {
          this.dialogRef.close({ success: true })
        } else {
        }
      }, err => {
        console.log('ERRRRROR')
        console.log(err)
        this.common.processError(err)
      });
    }
  }

  checkboxPasswordEvent(event) {
    if (event.checked) this.changePassword = true
    if (!event.checked) this.changePassword = false
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.imageSize = Math.round(this.imageChangedEvent.target.files[0].size / 1024)
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  loadImageFailed() {
    alert("Choose different image")
  }

  onCropDoneClick() {
    this.imageChangedEvent = '';
  }
}
