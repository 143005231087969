
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import 'rxjs/add/operator/map'

@Injectable({
    providedIn: 'root'
})
export class Profile {
    constructor(public api: ApiService) { }
    login(username: string, password: string) {
        let endpoint = 'users/login';
        return this
            .api
            .post(endpoint, {
                username: username,
                password: password
            })
            .map(resp => resp.json());
    }




    // CUSTOMER
    customerRegister(data) {
        let endpoint = 'customers/register';
        return this
            .api
            .post(endpoint, data)
            .map(resp => resp.json());
    }
    // Admin Users Roles
    getRoleGroups(page: number = 0, per_page: number = 0) {
        let endpoint = 'admin/roles';
        if (page > 0)
            endpoint += '?page=' + page
        if (per_page > 0)
            endpoint += '&per_page=' + per_page;
        return this
            .api
            .get(endpoint)
            .map(resp => resp.json());
    }
    getRoleGroupDetails(id: any) {
        let endpoint = 'admin/roles/' + id;
        return this
            .api
            .get(endpoint)
            .map(resp => resp.json());
    }
    addRole(data, isEdit) {
        let endpoint = 'admin/roles';
        if (isEdit) endpoint += '/' + data.id;
        if (isEdit) {
            return this
                .api
                .put(endpoint, data)
                .map(resp => resp.json());
        } else {
            return this
                .api
                .post(endpoint, data)
                .map(resp => resp.json());
        }

    }
    deleteRoleGroup(id) {
        let endpoint = 'admin/roles/delete/' + id;
        return this
            .api
            .delete(endpoint)
            .map(resp => resp.json());
    }
    // ADMIN PERMISSIONS
    getPermissions(page: number = 0, per_page: number = 0) {
        let endpoint = 'admin/permissions';
        if (page > 0)
            endpoint += '?page=' + page
        if (per_page > 0)
            endpoint += '&per_page=' + per_page;
        return this
            .api
            .get(endpoint)
            .map(resp => resp.json());
    }
    // Admin Users
    getAllAdminUsers(page: number = 0, per_page: number = 0) {
        let endpoint = 'users';
        if (page > 0)
            endpoint += '?page=' + page
        if (per_page > 0)
            endpoint += '&per_page=' + per_page;
        return this
            .api
            .get(endpoint)
            .map(resp => resp.json());
    }
    // Users
    addUser(data, isEdit) {
        let endpoint = 'users';
        if (isEdit) endpoint += '/' + data.id;
        let fData = new FormData();
        for (var key in data) {
            fData.append(key, data[key]);
        }
        if (isEdit) {
            return this
                .api
                .put(endpoint, data)
                .map(resp => resp.json());
        } else {
            return this
                .api
                .postAsFormData(endpoint, fData)
                .map(resp => resp.json());
        }

    }
    updateUserImage(data) {
        let endpoint = 'admin/vendors/image';
        let fData = new FormData();
        for (var key in data) {
            fData.append(key, data[key]);
        }
        return this
            .api
            .postAsFormData(endpoint, fData)
            .map(resp => resp.json());
    }
    deleteUser(id) {
        let endpoint = 'users/' + id;
        return this
            .api
            .delete(endpoint)
            .map(resp => resp.json());
    }
    getCommonDatas() {
        let endpoint = 'admin/settings';
        return this
            .api
            .get(endpoint)
            .map(resp => resp.json());
    }
    registerFcm(data: any) {
        let endpoint = 'admin/registerfcm';
        return this
          .api
          .post(endpoint, data)
          .map(resp => resp.json());
      }
}
