import { UserData } from 'app/providers/user-data';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource } from '@angular/material';
import { CommonProvider } from 'app/providers/common';
import { Profile } from 'app/providers/profile.service';
import { AddRoleGroupComponent } from '../add-role-group/add-role-group.component';
import { Router,NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-role-group',
  templateUrl: './role-group.component.html',
  styleUrls: ['./role-group.component.css']
})
export class RoleGroupComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  roleGroups: any = [];
  page: any = 1;
  per_page: any = 10;
  previousPageIndex: any
  total: number = 0;
  isAvailable: boolean = false
  displayedColumns = ['name','actions'];
  dataSource: any = [];
  columns: any = [
    {
      name: 'name',
      title: 'Group Name',
      key: 'name'
    },
  ]
  constructor(public dialog: MatDialog,private user:UserData,
     public profile: Profile, private common: CommonProvider,
    public route: Router) {
  }
  ngOnInit() {
    if (localStorage.getItem('role_per_page')) {
      this.per_page = localStorage.getItem('role_per_page')
      this.paginator.pageSize = this.per_page

    }
    if (localStorage.getItem('role_page')) {
      this.page = localStorage.getItem('role_page')
      this.previousPageIndex = this.paginator.pageIndex = +localStorage.getItem('role_previousPageIndex')
    }
    this.loadRoleGroups();

  }
  initDataSource() {
    this.roleGroups.sort = this.sort;
  }
  pageEvent(event) {
    localStorage.setItem('role_per_page', event.pageSize)
    localStorage.setItem('role_page', event.pageIndex + 1)
    localStorage.setItem('role_previousPageIndex', event.previousPageIndex + (event.pageIndex > event.previousPageIndex ? 1 : -1))
    this.per_page = event.pageSize;
    this.page = event.pageIndex + 1;
    this.loadRoleGroups()
  }

  loadRoleGroups() {
    this.isAvailable = false
    this.profile.getRoleGroups(this.page, this.per_page).subscribe(response => {
      if (response.success == true) {
        this.total = response.data.total;
        this.roleGroups = []
        this.roleGroups = response.data.data;
        if (!this.roleGroups || this.roleGroups.length < 1) {
          this.previousPageIndex = this.paginator.pageIndex = (+localStorage.getItem('role_previousPageIndex')) - 1
          this.page = (+localStorage.getItem('role_page')) - 1
          localStorage.setItem('role_page', this.page)
          localStorage.setItem('role_previousPageIndex', this.previousPageIndex)
          if (this.page < 1) {
            this.page = 1
          } else {
            this.loadRoleGroups()
          }
        }
        this.dataSource= new MatTableDataSource(this.roleGroups)
        this.dataSource.sort=this.sort
        if (this.roleGroups.length == 0) this.isAvailable = true
        this.initDataSource()
      } else {

      }
    }, err => {
      this.user.authorizedError(err)
    });
  }
  addUserRole(role: any = null): void {
    this.route.navigate(['/user/add-role'], {
      queryParams: {
        id: role ? role._id : null,
      }
    });
  }

  onClickView(row){
    let params = {
      'student':JSON.stringify(row)
    }
    console.log(params)
    this.route.navigate(['/user/role-group-view/'],{queryParams:row})
  }

  onDeleteRole(row) {
    if (confirm('Are you sure your want to delete?')) {
      this.profile.deleteRoleGroup(row._id).subscribe(response => {
        if (response.success == true) {
          this.common.showSnackBar('Deleted')
          this.loadRoleGroups();
        } else {
        }
      }, err => {
        this.common.processError(err)
      });
    }
  }
  onEditRole(row) {
    this.addUserRole(row)
  }
}
