
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EventService {
    USER_LOGIN = "user:login";
    USER_LOGOUT = 'user:logout';
    USER_PROFILE_UPDATED = 'user:profileUpdated';


    public configObservable = new Subject<string>();

    login() {
        this.configObservable.next(this.USER_LOGIN);
    }
    logout() {
        this.configObservable.next(this.USER_LOGOUT);
    }
    profileUpdated() {
        this.configObservable.next(this.USER_PROFILE_UPDATED);
    }


}