import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class OfferService {

  constructor(public api: ApiService) { }
  getOffer(page: number = 0, per_page: number = 0) {
    let endpoint = 'admin/offers';
    if (page > 0)
      endpoint += '?page=' + page
    if (per_page > 0)
      endpoint += '&per_page=' + per_page;
    return this
      .api
      .get(endpoint)
      .map(resp => resp.json());
  }
  addOffer(data, isEdit) {
    let endpoint = 'admin/offers';
    if (isEdit) endpoint += '/' + data.id;
    let fData = new FormData();
    for (var key in data) {
        fData.append(key, data[key]);
    }
    if (isEdit) {
        return this
            .api
            .put(endpoint, data)
            .map(resp => resp.json());
    } else {
        return this
            .api
            .postAsFormData(endpoint, fData)
            .map(resp => resp.json());
    }
  }
  deleteOffer(id) {
    let endpoint = 'admin/offers/delete/' + id;
    return this
      .api
      .delete(endpoint)
      .map(resp => resp.json());
  }
  viewProduct(id) {
    let endpoint = 'admin/offers/'+ id;
    return this
        .api
        .get(endpoint)
        .map(resp => resp.json());
}
addCoupon(data, isEdit) {

  let endpoint = 'admin/coupons';
    if (isEdit) endpoint += '/' + data.id;
    let fData = new FormData();
    for (var key in data) {
        fData.append(key, data[key]);
    }
    if (isEdit) {
        return this
            .api
            .put(endpoint, data)
            .map(resp => resp.json());
    } else {
        return this
            .api
            .postAsFormData(endpoint, fData)
            .map(resp => resp.json());
    }

}
getCoupon(page: number = 0, per_page: number = 0) {
  let endpoint = 'admin/coupons';
  if (page > 0)
    endpoint += '?page=' + page
  if (per_page > 0)
    endpoint += '&per_page=' + per_page;
  return this
    .api
    .get(endpoint)
    .map(resp => resp.json());
}
deleteCoupon(id) {
  let endpoint = 'admin/coupons/delete/' + id;
  return this
    .api
    .delete(endpoint)
    .map(resp => resp.json());
}
viewCoupon(id) {
  let endpoint = 'admin/coupons/'+ id;
  return this
      .api
      .get(endpoint)
      .map(resp => resp.json());
}

updateLogo(data) {
  console.log(data)
  let endpoint = 'admin/offers/image';
  let fData = new FormData();
  for (var key in data) {
      fData.append(key, data[key]);
  }
  return this
      .api
      .postAsFormData(endpoint, fData)
      .map(resp => resp.json());
}

sendPush(data){
  let endpoint='admin/offers/push'
  return this
  .api
  .post(endpoint,data)
  .map(resp=>resp.json());
}

getCoupenOrders(id){
let endpoint='admin/couponorders/'+id
return this
.api
.get(endpoint)
.map(resp=>resp.json())
}
}
