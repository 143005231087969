
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class CatalogService {

    constructor(public api: ApiService) { }
    // CATEGORY
    getCategories(page: number = 0, per_page: number = 0,category:any=null) {
        let endpoint = 'admin/catalog/categories?';
        if (page > 0)
            endpoint += 'page=' + page
        if (per_page > 0)
            endpoint += '&per_page=' + per_page;
        if (category !=null)
            endpoint=endpoint+'&name='+category;
        return this
            .api
            .get(endpoint)
            .map(resp => resp.json());
    }
    addCategory(data, isEdit) {
        let endpoint = 'admin/catalog/categories';
        if (isEdit) endpoint += '/' + data.id;
        let fData = new FormData();
        for (var key in data) {
            fData.append(key, data[key]);
            console.log(fData)
        }
        if (isEdit) {
            return this
                .api
                .put(endpoint, data)
                .map(resp => resp.json());
        } else {
            console.log(fData)
            return this
                .api
                .postAsFormData(endpoint, fData)
                .map(resp => resp.json());
        }

    }


    categoryDetails(id){
    let endpoint = 'admin/catalog/categories/' + id;
    return this
    .api
    .get(endpoint)
    .map(resp => resp.json()); 
    }

    // categoryImageAdd(data) {

    //     console.log("1111111111111111" , data)
    //     let endpoint = 'admin/catalog/categories/multiple/image/' + data.id;
    //     let fData = new FormData();
    //     for (var key in data) {
    //         fData.append(key, data[key]);
    //     }
    //     return this
    //         .api
    //         .postAsFormData(endpoint, fData)
    //         .map(resp => resp.json());
    // }



    categoryImageAdd(data, isEdit, id) {
        let endpoint = 'admin/catalog/categories/multiple/image/' + id;
        if (isEdit) endpoint += '/' + data.id;
        let fData = new FormData();
        for (var key in data) {
          fData.append(key, data[key]);
        }
        return this
          .api
          .postAsFormData(endpoint, fData)
          .map(resp => resp.json());
    
      }

      deleteCategoryMutiImage(id) {
        let endpoint = 'admin/catalog/categories/multiple/image/' + id;
        return this
          .api
          .delete(endpoint)
          .map(resp => resp.json());
      }

    deleteCategory(id) {
        let endpoint = 'admin/catalog/categories/delete/' + id;
        return this
            .api
            .delete(endpoint)
            .map(resp => resp.json());
    }
    updateCategoryLogo(data) {
        let endpoint = 'admin/catalog/categories/image/' + data.id;
        let fData = new FormData();
        for (var key in data) {
            fData.append(key, data[key]);
        }
        return this
            .api
            .postAsFormData(endpoint, fData)
            .map(resp => resp.json());
    }
    deleteCategoryImage(pid) {
        let endpoint = 'admin/catalog/categories/image/' + pid;
        return this
            .api
            .delete(endpoint)
            .map(resp => resp.json());
    }
    // END CATEGORY
    // OPTION START
    getOptions(page: number = 0, per_page: number = 0) {
        let endpoint = 'admin/catalog/options';
        if (page > 0)
            endpoint += '?page=' + page
        if (per_page > 0)
            endpoint += '&per_page=' + per_page;
        return this
            .api
            .get(endpoint)
            .map(resp => resp.json());
    }
    addOption(data, isEdit) {
        let endpoint = 'admin/catalog/options';
        if (isEdit) endpoint += '/' + data.id;
        if (isEdit) {
            return this
                .api
                .put(endpoint, data)
                .map(resp => resp.json());
        } else {
            return this
                .api
                .postAsFormData(endpoint, data)
                .map(resp => resp.json());
        }

    }
    deleteOption(id) {
        let endpoint = 'admin/catalog/options/delete/' + id;
        return this
            .api
            .delete(endpoint)
            .map(resp => resp.json());
    }
    // OPTION END
    //MANUFACTURE START
    addManufacture(data, isEdit) {
        let endpoint = 'admin/catalog/manufacturers';
        if (isEdit) endpoint += '/' + data.id;
        let fData = new FormData();
        for (var key in data) {
            fData.append(key, data[key]);
        }
        if (isEdit) {
            return this
                .api
                .put(endpoint, data)
                .map(resp => resp.json());
        } else {
            return this
                .api
                .postAsFormData(endpoint, fData)
                .map(resp => resp.json());
        }

    }
    getManufacture(page: number = 0, per_page: number = 0) {
        let endpoint = 'admin/catalog/manufacturers';
        if (page > 0)
            endpoint += '?page=' + page
        if (per_page > 0)
            endpoint += '&per_page=' + per_page;
        return this
            .api
            .get(endpoint)
            .map(resp => resp.json());
    }

    deleteManufacture(id) {
        let endpoint = 'admin/catalog/manufacturers/delete/' + id;
        return this
            .api
            .delete(endpoint)
            .map(resp => resp.json());
    }
    updateManufactureLogo(data) {
        let endpoint = 'admin/catalog/manufacturers/image/' + data.id;
        let fData = new FormData();
        for (var key in data) {
            fData.append(key, data[key]);
        }
        return this
            .api
            .postAsFormData(endpoint, fData)
            .map(resp => resp.json());
    }
    deleteManufactureImage(pid) {
        let endpoint = 'admin/catalog/manufacturers/image/' + pid;
        return this
            .api
            .delete(endpoint)
            .map(resp => resp.json());
    }
    //MANUFACTURE END
    //LUNCH BOX START - INSTITUTE
    addInstitute(data, isEdit) {
        let endpoint = 'admin/lunchbox/institute';
        if (isEdit) endpoint += '/' + data.id;
        let fData = new FormData();
        for (var key in data) {
            fData.append(key, data[key]);
        }
        if (isEdit) {
            return this
                .api
                .put(endpoint, data)
                .map(resp => resp.json());
        } else {
            return this
                .api
                .postAsFormData(endpoint, fData)
                .map(resp => resp.json());
        }

    }
    getInstitute(page: number = 0, per_page: number = 0) {
        let endpoint = 'admin/lunchbox/institute';
        if (page > 0)
            endpoint += '?page=' + page
        if (per_page > 0)
            endpoint += '&per_page=' + per_page;
        return this
            .api
            .get(endpoint)
            .map(resp => resp.json());
    }
    deleteInstitute(id) {
        let endpoint = 'admin/lunchbox/institute/delete/' + id;
        return this
            .api
            .delete(endpoint)
            .map(resp => resp.json());
    }
    viewInstitute(id) {
        let endpoint = 'admin/lunchbox/institute/' + id;
        return this
            .api
            .get(endpoint)
            .map(resp => resp.json());
    }
    updateInstituteLogo(data) {
        let endpoint = 'admin/lunchbox/institute/image';
        let fData = new FormData();
        for (var key in data) {
            fData.append(key, data[key]);
        }
        return this
            .api
            .postAsFormData(endpoint, fData)
            .map(resp => resp.json());
    }

    // INSTITTUTE SUB TYPE START
    addInstituteSubtype(data, isEdit) {
        let endpoint = 'admin/lunchbox/institute/subtypes';
        if (isEdit) endpoint += '/' + data.id;
        if (isEdit) {
            return this
                .api
                .put(endpoint, data)
                .map(resp => resp.json());
        } else {
            return this
                .api
                .post(endpoint, data)
                .map(resp => resp.json());
        }
    }
    getInstituteSubtype(page: number = 0, per_page: number = 0, inst_id) {
        let endpoint = 'admin/lunchbox/institute/subtypes';
        if (page > 0)
            endpoint += '?page=' + page;
        if (per_page > 0)
            endpoint += '&per_page=' + per_page+ '&institute_id=' + inst_id;
        return this
            .api
            .get(endpoint)
            .map(resp => resp.json());
    }
    deleteInstituteSubtype(id) {
        let endpoint = 'admin/lunchbox/institute/subtypes/delete/' + id;
        return this
            .api
            .delete(endpoint)
            .map(resp => resp.json());
    }
    viewInstituteSubType(id) {
        let endpoint = 'admin/lunchbox/institute/subtypes/' + id;
        return this
            .api
            .get(endpoint)
            .map(resp => resp.json());
    }
    addVendorProducts(data, isEdit, s_id) {
        let endpoint = 'admin/lunchbox/institute/vendor/' + s_id;
        if (isEdit) endpoint += '/' + data.id;
        if (isEdit) {
            return this
                .api
                .put(endpoint, data)
                .map(resp => resp.json());
        } else {
            return this
                .api
                .post(endpoint, data)
                .map(resp => resp.json());
        }
    }
    deleteVendorProduct(s_id, id) {
        let endpoint = 'admin/lunchbox/institute/vendor/' + s_id + '/' + id;
        return this
            .api
            .delete(endpoint)
            .map(resp => resp.json());
    }
    //  START UNIT
    getUnit(page: number = 0, per_page: number = 0) {
        let endpoint = 'admin/catalog/unit';
        if (page > 0)
            endpoint += '?page=' + page
        if (per_page > 0)
            endpoint += '&per_page=' + per_page;
        return this
            .api
            .get(endpoint)
            .map(resp => resp.json());
    }
    addUnit(data, isEdit) {
        let endpoint = 'admin/catalog/unit';
        if (isEdit) endpoint += '/' + data.id;
        if (isEdit) {
            return this
                .api
                .put(endpoint, data)
                .map(resp => resp.json());
        } else {
            return this
                .api
                .post(endpoint, data)
                .map(resp => resp.json());
        }
    }
    deleteUnit(id) {
        let endpoint = 'admin/catalog/unit/delete/' + id;
        return this
            .api
            .delete(endpoint)
            .map(resp => resp.json());
    }
    //  END UNIT

    getDetails(id:any=null) {
        let endpoint = 'admin/dashboard';
        if(id !=null)endpoint=endpoint+'?vendor_id='+id
        return this
            .api
            .get(endpoint)
            .map(resp => resp.json());
    }


    productFilter(product_name='',max_price='',min_price='',category_id='',manfacture_id='',
    product_availability='',sort_mode='',sort_field='',page:number=0,per_page:number=0){
        console.log(product_name)
        let endpoint = 'admin/catalog?';
        if (page > 0)
            endpoint += 'page=' + page +'&'
        if (per_page > 0)
            endpoint += 'per_page=' + per_page+'&';
        if (product_name != '')
            endpoint += 'product_name=' + product_name+'&';
        if (max_price != '')
            endpoint += 'max_price=' + max_price+'&';
        if (min_price != '')
            endpoint += 'min_price=' + min_price+'&';
        if (category_id != '')
            endpoint += 'category_id=' + category_id+'&';
        if (manfacture_id != '')
            endpoint += 'manfacture_id=' + manfacture_id+'&';
        if (product_availability != '')
            endpoint += 'product_availability=' + product_availability+'&';
        if (sort_mode != '' &&  sort_field != '')
            endpoint += 'sort_field='+sort_field+ '&sort_mode='+ sort_mode;
        return this
            .api
            .get(endpoint)
            .map(resp => resp.json());
    }

searchProduct(value,page:number=0,per_page:number=0){
    console.log(value)
        let endpoint = 'admin/catalog?';
        if(page >0)
        endpoint=endpoint+'page=' + page;
        if(per_page >0)
        endpoint =endpoint+'&per_page=' + per_page;
        if(value !=null)
        endpoint=endpoint+'&product_name='+value;
        return this
        .api
        .get(endpoint)
        .map(resp=>resp.json());
}

searchCatogary(value,page:number=0,per_page:number=0){
    console.log(value)
        let endpoint = 'admin/catalog/categories?';
        if(page >0)
        endpoint=endpoint+'page=' + page;
        if(per_page >0)
        endpoint =endpoint+'&per_page=' + per_page;
        if(value !=null)
        endpoint=endpoint+'&name='+value;
        return this
        .api
        .get(endpoint)
        .map(resp=>resp.json());
}
}
