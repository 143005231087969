import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonProvider } from 'app/providers/common';
import { Profile } from 'app/providers/profile.service';
import { UserData } from 'app/providers/user-data';
import { Router } from '@angular/router';
import { EventService } from 'app/providers/event.service';
import { global } from './../../../app.global';
import { SettingsService } from '../../../providers/settings.service';

@Component({
  selector: 'login',
  templateUrl: 'login.template.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  app_name: any = global.appTitle;
  settingsDetails:any=[]
  isloadingLogin: boolean = false;
  login: {
    username?: string,
    password?: string
  } = {};
  constructor(public general: CommonProvider, public profile: Profile, public userData: UserData,
    public router: Router, private event: EventService, private user: UserData,
    public settings:SettingsService) {
    // this.login.username = "admin@eximuz.com";
    // this.login.password = "admin123";
  }

  loadSettings() {
    this.settings.getSettings().subscribe(response => {
      if (response.success == true) {
        this.settingsDetails = []
        this.settingsDetails = response.data;
         if(response.data.product_code_prefix){
         localStorage.setItem('catCode',response.data.product_code_prefix)
        }
      } 
    }, err => {
      this.user.authorizedError(err)
    });
  }


  onLogin(loginForm: NgForm) {
    if (loginForm.valid) {
      this.isloadingLogin = true;
      this
        .profile
        .login(this.login.username, this.login.password)
        .subscribe(res => {
          console.log(res, 'login');
          console.log(res.data.vendor_id)

          if (res.data.vendor_id) localStorage.setItem('vendor', res.data.vendor_id)
          if (res.data.permissions) localStorage.setItem('role', res.data.permissions.original.data)
          localStorage.setItem('orderView', 'grid')
          if (res.success == true) {
            
            this.userData.updateLoginData(res);
            this.event.login()
            this.general.showToastSuccess('Successfully Logged In')
            this.loadSettings()
            // this.profile.getCommonDatas().subscribe(res =>{
            //   localStorage.setItem('catCode', res.data.catalog.product_code_prefix)
            // })
            this.router.navigate(['/starterview']);
          }
          this.isloadingLogin = false;
        }, err => {
          console.error('ERROR', err);
          this
            .general
            .showToastError('Invalid Email or password', 'Loig Failed')
          this
            .isloadingLogin = false;
          return false;
        });

    }
  }
}
