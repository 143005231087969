import { Component } from '@angular/core';
// import { global } from '../../../app.global';
import { CommonProvider } from '../../../providers/common';
@Component({
  selector: 'footer',
  templateUrl: 'footer.template.html'
})
export class FooterComponent { 
  // footerName :any
  constructor(public common:CommonProvider){
  //   this.footerName = global.appName
  }
}
