
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class VendorService {
    constructor(public api: ApiService) { }
    // VENDOR
    addVendor(data, isEdit) {
        let endpoint = 'admin/vendors';
        if (isEdit) endpoint += '/' + data.id;
        let fData = new FormData();
        for (var key in data) {
            fData.append(key, data[key]);
        }
        if (isEdit) {
            return this
                .api
                .put(endpoint, data)
                .map(resp => resp.json());
        } else {
            return this
                .api
                .postAsFormData(endpoint, fData)
                .map(resp => resp.json());
        }
    }


    updateShowVentor(data, id) {
        let endpoint = 'admin/vendors/' + id;
        // endpoint += '/' + data.id;
       
            return this
                .api
                .put(endpoint, data)
    }



    getVendors(page: number = 0, per_page: number = 0,branchName:any=null,branchMobile:any=0,cityId:number=0) {
        let endpoint = 'admin/vendors?';
        if (page > 0)
            endpoint += 'page=' + page
        if (per_page > 0)
            endpoint += '&per_page=' + per_page;
        if(branchName !=null)
        endpoint =endpoint+'&vendor_name='+branchName
        if(branchMobile !=0)
        endpoint =endpoint+'&vendor_mobile='+branchMobile
        if(cityId !=0)
        endpoint =endpoint+'&city_name='+cityId
        return this
            .api
            .get(endpoint)
            .map(resp => resp.json());
    }
    deleteVendor(id) {
        let endpoint = 'admin/vendors/delete/' + id;
        return this
            .api
            .delete(endpoint)
            .map(resp => resp.json());
    }
    viewVendor(id) {
        let endpoint = 'admin/vendors/' + id;
        return this
            .api
            .get(endpoint)
            .map(resp => resp.json());
    }
    updateVendorLogo(data) {
        let endpoint = 'admin/vendors/image/'+data.id;
        let fData = new FormData();
        for (var key in data) {
            fData.append(key, data[key]);
        }
        return this
            .api
            .postAsFormData(endpoint, fData)
            .map(resp => resp.json());
    }
    // VENDOR CATEGORY
    getVendorsCategory(page: number = 0, per_page: number = 0) {
        let endpoint = 'admin/vendors/categories';
        if (page > 0)
            endpoint += '?page=' + page
        if (per_page > 0)
            endpoint += '&per_page=' + per_page;
        return this
            .api
            .get(endpoint)
            .map(resp => resp.json());
    }
    addVendorCategory(data, isEdit) {
        let endpoint = 'admin/vendors/categories';
        if (isEdit) endpoint += '/' + data.id;
        let fData = new FormData();
        for (var key in data) {
            fData.append(key, data[key]);
        }
        if (isEdit) {
            return this
                .api
                .put(endpoint, data)
                .map(resp => resp.json());
        } else {
            return this
                .api
                .postAsFormData(endpoint, fData)
                .map(resp => resp.json());
        }
    }
    updateVendorCatLogo(data) {
        let endpoint = 'admin/vendors/categories/image';
        let fData = new FormData();
        for (var key in data) {
            fData.append(key, data[key]);
        }
        return this
            .api
            .postAsFormData(endpoint, fData)
            .map(resp => resp.json());
    }
    deleteVendorCategory(id) {
        let endpoint = 'admin/vendors/categories/delete/' + id;
        return this
            .api
            .delete(endpoint)
            .map(resp => resp.json());
    }
    // VENDOR CATEGORY END
    // VENDOR CONTACT START
    addVendorContact(data, isEdit) {
        let endpoint = 'admin/vendors/contacts';
        if (isEdit) endpoint += '/' + data.id;
        if (isEdit) {
            return this
                .api
                .put(endpoint, data)
                .map(resp => resp.json());
        } else {
            return this
                .api
                .post(endpoint, data)
                .map(resp => resp.json());
        }

    }
    getVendorContact(vendor_id='',page: number = 0, per_page: number = 0) {
        let endpoint = 'admin/vendors/contacts?';
        if (vendor_id != '')
            endpoint += 'vendor_id=' + vendor_id
        if (page > 0)
            endpoint += '&page=' + page
        if (per_page > 0)
            endpoint += '&per_page=' + per_page;
        return this
            .api
            .get(endpoint)
            .map(resp => resp.json());
    }
    deleteVendorContact(id) {
        let endpoint = 'admin/vendors/contacts/delete/' + id;
        return this
            .api
            .delete(endpoint)
            .map(resp => resp.json());
    }

    addVendorLocation(data, isEdit) {
        let endpoint = 'admin/location';
        if (isEdit) endpoint += '/' + data.id;
        if (isEdit) {
            return this
                .api
                .put(endpoint, data)
                .map(resp => resp.json());
        } else {
            return this
                .api
                .post(endpoint, data)
                .map(resp => resp.json());
        }

    }
    getVendorLocation(vendor_id='',page: number = 0, per_page: number = 0) {
        let endpoint = 'admin/location/'+vendor_id+'?';
        if (vendor_id != '')
            endpoint += 'vendor_id=' + vendor_id
        if (page > 0)
            endpoint += '&page=' + page
        if (per_page > 0)
            endpoint += '&per_page=' + per_page;
        return this
            .api
            .get(endpoint)
            .map(resp => resp.json());
    }
    deleteVendorLocation(id) {
        let endpoint = 'admin/location/delete/' + id;
        return this
            .api
            .delete(endpoint)
            .map(resp => resp.json());
    }

    addVendorCommission(data, isEdit) {
        let endpoint = 'admin/vendor-anjos-commission';
        if (isEdit) endpoint += '/' + data.id;
        if (isEdit) {
            return this
                .api
                .put(endpoint, data)
                .map(resp => resp.json());
        } else {
            return this
                .api
                .post(endpoint, data)
                .map(resp => resp.json());
        }

    }
    getVendorCommission(vendor_id='',page: number = 0, per_page: number = 0) {
        let endpoint = 'admin/vendor-anjos-commission/'+vendor_id;
        if (page > 0)
            endpoint += '?page=' + page
        if (per_page > 0)
            endpoint += '&per_page=' + per_page;
        return this
            .api
            .get(endpoint)
            .map(resp => resp.json());
    }
    deleteVendorCommission(id) {
        let endpoint = 'admin/vendor-anjos-commission/delete/' + id;
        return this
            .api
            .delete(endpoint)
            .map(resp => resp.json());
    }

    searchVendor(value,page:number=0,per_page:number=0){
        console.log(value)
            let endpoint = 'admin/vendors?';
            if(page >0)
            endpoint=endpoint+'page=' + page;
            if(per_page >0)
            endpoint =endpoint+'&per_page=' + per_page;
            if(value !=null)
            endpoint=endpoint+'&vendor_name='+value;
            return this
            .api
            .get(endpoint)
            .map(resp=>resp.json());
      }
}
