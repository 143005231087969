import { Directive, ElementRef, Input } from '@angular/core';
import { CommonProvider } from 'app/providers/common';
@Directive({
  selector: '[appPermissions]'
})
export class PermissionsDirective {
  roles: any = []
  constructor(private el: ElementRef, private common: CommonProvider) {

  }

  ngOnInit() {

    this.roles.push(localStorage.getItem('role').split(','))
    if (this.roles[0] != null && this.roles[0].find(x => x == this.appPermissions)) {
      this.el.nativeElement.style.display = 'inline';
     
    } else {
      this.el.nativeElement.style.display = 'none';
    }
  }
  @Input() appPermissions: string
}
