import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(public api:ApiService) { }


  getNotificationList(page,per_page){
    let endpoint='admin/push-notification'
    if(page >0)endpoint=endpoint+'?page='+page
    if(per_page >0)endpoint=endpoint+'&per_page='+per_page
    return this
    .api
    .get(endpoint)
    .map(resp=>resp.json())
  }

  sendNotification(data){
    let endpoint='admin/push-notification/add'
    return this
    .api
    .post(endpoint,data)
    .map(resp=>resp.json())
  }
}
