import { UserData } from 'app/providers/user-data';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource } from '@angular/material';
import { formatDate } from '@angular/common';
import { Profile } from 'app/providers/profile.service';
import { AddUserComponent } from '../add-user/add-user.component';
import { CommonProvider } from 'app/providers/common';
import { ImageHelperService } from 'app/_helpers/imageHelper.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
export interface UsersData {
  name: string;
  email: string;
  userGroup: string;
}

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css']
})
export class UsersListComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  usersList: any = [];
  page: any = 1;
  per_page: any = 10;
  previousPageIndex: any
  total: number = 0;
  users: any = []
  imageChangedEvent: any = '';
  croppedImage: any = '';
  isAvailable: boolean = false
  displayedColumns = ['image','name', 'username','mobile','user_role','actions'];
  dataSource: any = [];
  columns: any = [
    {
      name: 'name',
      title: 'Name',
      key: 'name'
    },
    {
      name: 'username',
      title: 'Email ID',
      key: 'username'
    },
    {
      name: 'mobile',
      title: 'Mobile Number',
      key: 'mobile'
    },
  ]
  constructor(public dialog: MatDialog, private common: CommonProvider, public profile: Profile
    , private imageHelper: ImageHelperService,private user:UserData ) {
  }
  ngOnInit() {
    if (localStorage.getItem('user_per_page')) {
      this.per_page = localStorage.getItem('user_per_page')
      this.paginator.pageSize = this.per_page

    }
    if (localStorage.getItem('user_page')) {
      this.page = localStorage.getItem('user_page')
      this.previousPageIndex = this.paginator.pageIndex = +localStorage.getItem('user_previousPageIndex')
    }
    this.loadUsers();
  }
  initDataSource() {
    this.users.sort = this.sort;
  }
  pageEvent(event) {
    localStorage.setItem('user_per_page', event.pageSize)
    localStorage.setItem('user_page', event.pageIndex + 1)
    localStorage.setItem('user_previousPageIndex', event.previousPageIndex + (event.pageIndex > event.previousPageIndex ? 1 : -1))
    this.per_page = event.pageSize;
    this.page = event.pageIndex + 1;
    this.previousPageIndex = event.previousPageIndex
    this.loadUsers()
  }
  resetData(){
    this.loadUsers()
  }
  loadUsers() {
    this.isAvailable = false
    if (this.page < 1) this.page = 1;
    this.profile.getAllAdminUsers(this.page, this.per_page).subscribe(response => {
      if (response.success == true) {
        this.total = response.data.total;
        this.users = []
        this.users = response.data.data;
        if (!this.users || this.users.length < 1) {
          this.previousPageIndex = this.paginator.pageIndex = (+localStorage.getItem('user_previousPageIndex')) - 1
          this.page = (+localStorage.getItem('user_page')) - 1
          localStorage.setItem('user_page', this.page)
          localStorage.setItem('user_previousPageIndex', this.previousPageIndex)
          if (this.page < 1) {
            this.page = 1
          } else {
            this.loadUsers()
          }
        }
        this.dataSource=new MatTableDataSource(this.users)
        this.dataSource.sort=this.sort
        if (this.users.length == 0) this.isAvailable = true
        // this.initDataSource()
      } else {
      }
    }, err => {
      this.user.authorizedError(err)
    });
  }

  addUser(user: any = null): void {
    const dialogRef = this.dialog.open(AddUserComponent, {
      height: '100%',
      panelClass: 'form-modal-container',
      data: {
        userDetails: user ? user : null
      },
    });
    dialogRef.updatePosition({ bottom: '0px', right: '0' });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.success && result.success == true) {
        this.loadUsers();
      }
    });
  }
  onDeleteUser(row) {
    if (confirm('Are you sure your want to delete?')) {
      this.profile.deleteUser(row._id).subscribe(response => {
        if (response.success == true) {
          this.common.showSnackBar('Deleted')
          this.loadUsers();
        } else {
        }
      }, err => {
        this.common.processError(err)
      });
    }
  }


  onEditUser(row) {
    this.addUser(row)
  }

}
