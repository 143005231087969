import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CatalogService } from 'app/providers/catalog.service';
import { CommonProvider } from 'app/providers/common';
import { CustomerService } from 'app/providers/customer.service';
import { NotificationService } from 'app/providers/notification.service';
import { OfferService } from 'app/providers/offer.service';
import { OrderService } from 'app/providers/orderService';
import { PromotionBannerService } from 'app/providers/promotion-banner.service';
import { VendorService } from 'app/providers/vendor.service';
import { UserData } from 'app/providers/user-data';

@Component({
  selector: 'app-add-notification',
  templateUrl: './add-notification.component.html',
  styleUrls: ['./add-notification.component.css']
})
export class AddNotificationComponent implements OnInit {

  addNotificationForm:FormGroup
  customer_type:any;
  total:any;
  customerGroups:any=[]
  vendors:any=[]
  offers:any=[]
  previousPageIndex:any;
  actionType:any;
  category:any=[]
  productDetails:any=[]
  submitted:boolean=false;
  customerDetils:any;
  is_order:boolean=false;
  orderDetails:any=[]
  groupname:any;
  banners:any=[]
  filterBanner: any;
  activeId: any;
  banner_action_type: any;
  activeBanner: any;
  constructor(public formBuilder:FormBuilder,public customer:CustomerService,public common:CommonProvider,public vendor:VendorService,public offer:OfferService,public catalog:CatalogService,
    public notification:NotificationService,public dialogRef: MatDialogRef<AddNotificationComponent>,public user:UserData,
    @Inject(MAT_DIALOG_DATA) public data: any,public order:OrderService,public banner:PromotionBannerService) {
 
      console.log(data)

      if(data){
        if(data.customer_data){
          this.customerDetils=data.customer_data       
       console.log(this.customerDetils)
       if(this.customerDetils !=undefined){
       this.is_order=true;
       this.loadOrder()
        }

       }

       if(data.flag){
         this.actionType=data.flag
       }


      }
     }

  ngOnInit() {
    this.addNotificationForm =this.formBuilder.group({
      title:['',Validators.required],
      description:['',Validators.required],
      customer_type:new FormControl('',Validators.required),
      customer_id:new FormControl('',),
      image:[''],
      action_type:['',Validators.required],
      action_id:new FormControl('',)
    })
    if(this.data.offer){
      // this.addNotificationForm.controls.action_id.setValue('data.offer_id');
      this.addNotificationForm.get('action_type').patchValue(this.data.flag);
      this.addNotificationForm.get('action_id').patchValue(this.data.offer._id);
      this.addNotificationForm.get('title').patchValue(this.data.offer.offer_name);
      this.addNotificationForm.get('description').patchValue(this.data.offer.offer_description);
    }
    if(this.data.banner){
      // this.addNotificationForm.controls.action_id.setValue('data.offer_id');
      this.addNotificationForm.get('action_type').patchValue(this.data.flag);
      this.addNotificationForm.get('action_id').patchValue(this.data.banner._id);
      this.addNotificationForm.get('title').patchValue(this.data.banner.banner_name);
    }
    this.loadGroup()
    this.loadVendors()
    this.loadOffer()
    this.loadAllCategories()
    this.loadProducts()
    this.loadBanner()

    if(this.is_order){
      console.log("kkkkk")
      this.customer_type='customer'
      this.addNotificationForm.controls.customer_type.setValue('customer');
      this.addNotificationForm.controls.customer_id.setValue(this.customerDetils._id);
    }
  }


  addNotification(){

  }


  selectAudience(data){
  this.customer_type=data
  }


  
  loadGroup() {
    this.customer.getGroup(1, 100).subscribe(response => {
      if (response.success == true) {
        this.total = response.data.total;
        this.customerGroups = []
        this.customerGroups = response.data.data;
      } else {
      }
    }, err => {
    });
  }


  loadVendors() {
    this.vendor.getVendors(1, 100).subscribe(response => {
      if (response.success == true) {
        this.total = response.data.total;
        this.vendors = []
        this.vendors = response.data.data;
        }
    }, err => {
    });
  }


  loadOffer() {

    this.offer.getOffer(1,100).subscribe(response => {
      if (response.success == true) {
        this.total = response.data.total;
        this.offers = []
        this.offers = response.data.data
      } else {
      }
    }, err => {

    });
  }

  SelectActionType(data){
  this.actionType=data
  this.addNotificationForm.get("action_type").setValue(this.actionType)
  }

  loadAllCategories() {
    this.catalog.getCategories().subscribe(response => {
      if (response.success == true) {
        this.category = []
        this.category = response.data.data
      }

    }, err => {
    });
  }


    loadProducts() {
    this.catalog.productFilter().subscribe(response => {
      if (response.success == true) {
        this.total = response.data.total;
        this.productDetails = []
        this.productDetails = response.data;

      }
    }, err => {

    });
  }


  actionChange(evt, id){
    console.log('id' , id)
    this.activeId = id
    this.filterBanner = this.banners.filter(function (e) {
      console.log("eeeeeeeeeeeeee" , e)
        return e._id ==  id;
    });
    console.log("this.filterBanner " ,   this.filterBanner[0].action_type)

  }


  get aof() { return this.addNotificationForm.controls; } 


  loadBanner() {
    this.banner.getBanner(1,500).subscribe(response => {
      if (response.success == true) {
        this.banners = response.data.data;
        console.log("this.banners" , this.banners)
        } else {
      }
    }, err => {
      this.user.authorizedError(err)
    });
  }


  onSendNotification(){
  this.submitted=true
   console.log(this.actionType)

   console.log("this.filterBanner11111111 " ,   this.filterBanner[0].action_type)

  if(this.customer_type =='Customer_groups' || this.customer_type =='branches'){
    console.log("kkkkkk")
    this.addNotificationForm.controls.customer_id.setValidators([Validators.required])
    this.addNotificationForm.controls.customer_id.updateValueAndValidity();
  }

  if(this.actionType =='orders' || this.actionType =='products' || this.actionType =='Catogery' || this.actionType =='offers' ){
    this.addNotificationForm.controls.action_id.setValidators([Validators.required])
    this.addNotificationForm.controls.action_id.updateValueAndValidity();
  }

  if(this.addNotificationForm.invalid){
    return
  }
  if(this.addNotificationForm.valid){


    var data={
      title:this.aof.title.value,
      ref_type:this.customer_type,
      ref_id:+this.aof.customer_id.value,
      image_url:this.aof.image.value,
      description:this.aof.description.value,
      item_type:  this.actionType != 'banner' ? this.actionType : this.filterBanner[0].action_type,
      // item_type:  this.actionType,
      item_id:+this.aof.action_id.value
    }



    this.notification.sendNotification(data).subscribe(resp=>{
      if(resp.success){
        this.dialogRef.close({ success: true })
      }
    })
  }
  }

  loadOrder() {
    console.log('+++++++++++++++++++++++++++++')
    this.order.getOrder(1,500,this.customerDetils._id).subscribe(response => {
      if (response.success == true) {
      this.total = response.data.total;
    console.log(this.total,'+++++++++++++++++++++++++++++')
    this.orderDetails = response.data.data;
        console.log(this.orderDetails)
      } 
    }, err => {
    });
  }


}
