import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { Profile } from 'app/providers/profile.service';

@Component({
  selector: 'app-role-group-view',
  templateUrl: './role-group-view.component.html',
  styleUrls: ['./role-group-view.component.css']
})
export class RoleGroupViewComponent implements OnInit {

  roleData:any=[];
  total:any=[]
  permissions=[]
  tickData:any=[]
  parentData:any=[]
  chaildData:any=[]

  constructor(public route:ActivatedRoute,public profile:Profile) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params=> {
      this.roleData=params
      console.log(this.roleData)
  })

  this.profile.getPermissions().subscribe(response => {
    if (response.success == true) {
      this.total = response.data.total;
      this.permissions = response.data;
      console.log(this.permissions)
    this.getData()
    }
  })
}

getData(){
  this.permissions.forEach(element => {
    this.roleData.permission_ids.forEach(element1 => {
      if(element._id == element1){
      element.is_show = true
      }
      element.children.forEach(element2 => {
        if(element2._id==element1){
          element2.is_show = true
        }
      element2.children.forEach(element3 => {
        if(element3._id==element1){
          element3.is_show = true
        }
      });
      });
    });
  });
  console.log(this.tickData)
}
}
