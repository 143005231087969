import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(public api: ApiService) { }
  getProducts(page: number = 0, per_page: number = 0,venId=0) {
    let endpoint = 'admin/catalog?';
    if (page > 0)
      endpoint += 'page=' + page + '&'
    if (per_page > 0)
      endpoint += 'per_page=' + per_page + '&';
    if(venId > 0)
      endpoint += 'vendor_id=' + venId
    return this
      .api
      .get(endpoint)
      .map(resp => resp.json());
  }

  getReview(id){
    let endpoint='admin/catalog/'+id+'/review';
    return this.
    api.
    get(endpoint)
    .map(resp=>resp.json());
  }

  onDeleteReview(id){
    let endpoint='admin/catalog/'+id+'/review/delete';
    return this.
    api.
    delete(endpoint)
    .map(resp=>resp.json())
  }
  addProduct(data, isEdit) {
    let endpoint = 'admin/catalog';
    if (isEdit) endpoint += '/' + data.id;
    let fData = new FormData();
    for (var key in data) {
      fData.append(key, data[key]);
    }
    if (isEdit) {
      return this
        .api
        .put(endpoint, data)
        .map(resp => resp.json());
    } else {
      return this
        .api
        .postAsFormData(endpoint, fData)
        .map(resp => resp.json());
    }

  }
  deleteProduct(id) {
    let endpoint = 'admin/catalog/delete/' + id;
    return this
      .api
      .delete(endpoint)
      .map(resp => resp.json());
  }
  showProduct(id) {
    let endpoint = 'admin/catalog/' + id;
    return this
      .api
      .get(endpoint)
      .map(resp => resp.json());
  }
  updateProductLogo(data,pid) {
    let endpoint = 'admin/catalog/image/'+pid;
    let fData = new FormData();
    for (var key in data) {
      fData.append(key, data[key]);
    }
    return this
      .api
      .postAsFormData(endpoint, fData)
      .map(resp => resp.json());
  }
  addvendorProduct(data, isEdit, prod_id) {
    var endpoint:any;
    console.log(data)
    if(data.vendors.vendor_id != 0){
      endpoint = 'admin/catalog/vendor/' + prod_id;
    }
    if(data.vendors.vendor_id == 0){
      endpoint = 'admin/catalog/vendor_all/' + prod_id;
    }
    console.log(endpoint)
    if (isEdit) endpoint += '/' + data.id;
    if (isEdit) {
      return this
        .api
        .put(endpoint, data)
        .map(resp => resp.json());
    } else {
      return this
        .api
        .post(endpoint, data)
        .map(resp => resp.json());
    }
  }
  deleteVendorProduct(pid, id) {
    let endpoint = 'admin/catalog/vendor/' + pid + '/' + id;
    return this
      .api
      .delete(endpoint)
      .map(resp => resp.json());
  }

  addVariantProduct(data, isEdit, id) {
    let endpoint = 'admin/catalog/variant/' + id;
    if (isEdit) endpoint += '/' + data.id;
    if (isEdit) {
      return this
        .api
        .put(endpoint, data)
        .map(resp => resp.json());
    } else {
      return this
        .api
        .post(endpoint, data)
        .map(resp => resp.json());
    }
  }
  deleteVariantProduct(pid, id) {
    let endpoint = 'admin/catalog/variant/' + pid + '/' + id;
    return this
      .api
      .delete(endpoint)
      .map(resp => resp.json());
  }

  addAvailableProduct(data, isEdit, id) {
    let endpoint = 'admin/catalog/availabletime/' + id;
    if (isEdit) endpoint += '/' + data.id;
    if (isEdit) {
      return this
        .api
        .put(endpoint, data)
        .map(resp => resp.json());
    } else {
      return this
        .api
        .post(endpoint, data)
        .map(resp => resp.json());
    }
  }
  deleteAvailableProduct(pid, id) {
    let endpoint = 'admin/catalog/availabletime/' + pid + '/' + id;
    return this
      .api
      .delete(endpoint)
      .map(resp => resp.json());
  }
  addProductImage(data, isEdit, id) {
    let endpoint = 'admin/catalog/product_image/' + id;
    if (isEdit) endpoint += '/' + data.id;
    let fData = new FormData();
    for (var key in data) {
      fData.append(key, data[key]);
    }
    return this
      .api
      .postAsFormData(endpoint, fData)
      .map(resp => resp.json());

  }
  deleteMultiProductImage(pid, id) {
    let endpoint = 'admin/catalog/product_image/' + pid + '/' + id;
    return this
      .api
      .delete(endpoint)
      .map(resp => resp.json());
  }
  deleteProductImage(pid) {
    console.log(pid)
    let endpoint = 'admin/catalog/image/'+pid;
    return this
      .api
      .delete(endpoint)
      .map(resp => resp.json());
  }

  addBulkPrice(data,isEdit,id){
    let endpoint ='admin/catalog/product/bulkprice';
    if(id>0)
    endpoint =endpoint+'/'+id;
    if(isEdit){
      return this
      .api
      .put(endpoint,data)
      .map(resp=>resp.json())
    }else{
    return this
    .api
    .post(endpoint,data)
    .map(resp=>resp.json())
    }
  }

  deleteBulk(id){
    let endpoint= 'admin/catalog/product/bulkprice/delete/'+id
    return this
    .api
    .delete(endpoint)
    .map(resp=>resp.json())
  }

  getCustomerGroup(){
    let endpoint = 'admin/customers/groups';
    return this
      .api
      .get(endpoint)
      .map(resp => resp.json());
  }

  getProductReport(date = null,type=null,per_page=0){
    let endpoint = 'admin/report/all?';
      if (date != null) endpoint += 'current_date=' + date + '&'
      if (type != null) endpoint += 'type=' + type + '&'
      if (per_page > 0) endpoint += 'per_page=' + per_page
        return this
            .api
            .get(endpoint)
            .map(resp => resp.json());
  }
}
