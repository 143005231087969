import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ImageHelperService {
    constructor() { }
    base64ToImageFile(base64Img) {
        console.log(base64Img)
        var byteString;
        if (base64Img.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(base64Img.split(',')[1]);
        else
            byteString = unescape(base64Img.split(',')[1]);
        // separate out the mime component
        var mimeString = base64Img.split(',')[0].split(':')[1].split(';')[0];
        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        var blob = new Blob([ia], { type: mimeString });
        console.log(blob)
        var resultImg = new File([blob], "fileName.jpeg", {
            type: "'image/jpeg'"
        });
        console.log(resultImg)
        return resultImg;
    }
}
