import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MustMatch } from 'app/_helpers/must-match.validator';
import { CommonProvider } from 'app/providers/common';
import { Profile } from 'app/providers/profile.service';
import { UserData } from 'app/providers/user-data';
import { Router } from '@angular/router';
import { global } from './../../../app.global';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  app_name: any =  global.appTitle;
  registerForm: FormGroup;
  submitted = false;
  isRegisterLoading: boolean = false;
  constructor(private formBuilder: FormBuilder, public general: CommonProvider, public profile: Profile,
    public user: UserData, private router: Router) { }


  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      fullname: ['junaid', Validators.required],
      mobile: ['8129829342', [Validators.required,
      Validators.minLength(10),
      Validators.maxLength(10),
      Validators.pattern(/^[5-9]\d{9}$/),
      ]],
      email: ['junaid@gmail.com', [Validators.required, Validators.email]],
      password: ['junaid', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['junaid', Validators.required],
    }, {
        validator: MustMatch('password', 'confirmPassword')
      });
  }
  get rf() { return this.registerForm.controls; }
  onSubmitRegister() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    var data = {
      name: this.registerForm.get('fullname').value,
      mobile: this.registerForm.get('mobile').value,
      email: this.registerForm.get('email').value,
      password: this.registerForm.get('password').value,
      password_confirmation: this.registerForm.get('confirmPassword').value
    }
    this.isRegisterLoading = true;
    this
      .profile
      .customerRegister(data)
      .subscribe(res => {
        console.log(res);
        if (res.success == true) {
          console.log('Success ssdsdsd')
          this
            .user
            .updateLoginData(res);
          this
            .general
            .showToastSuccess('Successfully Registered')
          this.router.navigate(['/starterview']);
        } else if (res.success == false) {
          this
            .general
            .showToastError(res.message)
        }
        this.isRegisterLoading = false;
      }, err => {
        console.error('ERROR', err);
        this
          .general
          .processError(err)
        this
          .isRegisterLoading = false;
        return false;
      });

  }
}
