// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase : {
    apiKey: "AIzaSyCb1OAuu6yn_xoi2Uo93BiKYLq_bmAhNEE",
    authDomain: "aloob-85f12.firebaseapp.com",
    databaseURL: "https://aloob-85f12.firebaseio.com",
    projectId: "aloob-85f12",
    storageBucket: "aloob-85f12.appspot.com",
    messagingSenderId: "507629112250",
    appId: "1:507629112250:web:bef970d12001e5520f6b83",
    measurementId: "G-CLNYQCM33Q"
  },
  menu:{
    is_dashboard:true
  }
};

export const environmentAloob = {
  production: false,
  firebase : {
    apiKey: "AIzaSyCb1OAuu6yn_xoi2Uo93BiKYLq_bmAhNEE",
    authDomain: "aloob-85f12.firebaseapp.com",
    databaseURL: "https://aloob-85f12.firebaseio.com",
    projectId: "aloob-85f12",
    storageBucket: "aloob-85f12.appspot.com",
    messagingSenderId: "507629112250",
    appId: "1:507629112250:web:bef970d12001e5520f6b83",
    measurementId: "G-CLNYQCM33Q"
  },
  menu:{
    is_dashboard:true,
    is_catalog:true,
    is_order : true,
    is_customer : true,
    is_location : true,
    is_vendor : false,
    is_promotion_banner : true,
    is_admin_user : true,
    is_offer : true,
    is_customer_membership : false,
    is_delivery_boy : false,
    is_settings : true,
    is_Schemes:false,
    is_pickup:false,
  },
  sub_menu:{
    is_catalog_lunchbox : false,
    is_vendor_categories:false,
    is_customer_group:false,
    is_coupon: false,
    is_settings_tax: false,
    is_settings_delivery: false,
    is_settings_spin: false,
    is_settings_premium: false,
    is_settings_settings: true,
    product_count:false,
   },
   common_label:{
    city_label :'City',
    location_label :'Location',
    vendor_label_name :'Branch',
    commission_module: false,
    use_wallet: false,
    is_Password:false,
    is_Approve:false,
    is_Price:'SR',
    is_bulkProduct:false,
    coin_label:'Aloob Credit',
    is_schedule_order_interval: false,

   },
   project_details:{
    appName: 'Aloob',
    appTitle: 'Aloob',
    titleSperator : ' | ',
    apiUrl:"https://api.aloobgroup.com/api/v1",
    logo:'../assets/images/logo.png',
    icon: 'assets/images/icon.png"',
    footer_name: 'Aloob'
   }
   
};
export const environmentFoodSign = {
  production: false,
  firebase : {
    apiKey: "AIzaSyDYV0x2I-8ujAsVrAKr536RqwqSjoTB3hI",
    authDomain: "foodsign-87cbd.firebaseapp.com",
    databaseURL: "https://foodsign-87cbd.firebaseio.com",
    projectId: "foodsign-87cbd",
    storageBucket: "foodsign-87cbd.appspot.com",
    messagingSenderId: "490291510877",
    appId: "1:490291510877:web:175d65990a52bb875417b4",
    measurementId: "G-E8HVTQT505"
  },
  menu:{
    is_dashboard:true,
    is_catalog:true,
    is_order : true,
    is_customer : true,
    is_location : true,
    is_vendor : true,
    is_promotion_banner : true,
    is_admin_user : true,
    is_offer : true,
    is_customer_membership : false,
    is_delivery_boy : true,
    is_settings : true,
    is_Schemes:true,
    is_pickup:true,
    is_social_media:false,
    is_notification:false
  },
  sub_menu:{
    is_catalog_lunchbox : false,
    is_vendor_categories:false,
    is_coupon: true,
    is_settings_tax: false,
    is_settings_delivery: false,
    is_settings_spin: false,
    is_settings_premium: false,
    is_settings_settings: true,
    is_customer_group:false,
    is_addVendor:true,
    is_floatingCash:true,
    is_delivery_boyTracking:true,
    is_delivery_boycommition:true,
    is_delivery_boyattendance:true,
    is_deliveryTeams:true,
    product_count:false,
    is_scheme:false,
    product_calory:false,
    enable_state_location:false,
    enabled_arabic:false,
   },
   common_label:{
    city_label :'City',
    location_label :'Location',
    vendor_label_name :'Branch',
    commission_module: false,
    use_wallet: true,
    delivery_salary:false,
    delivery_commission_type:false,
    is_Password:false,
    is_Approve:false,
    is_Price:'RS',
    is_bulkProduct:false,
    coin_label:'Foodsign Credit',
    location_view_label:'Taluk',
    coin_rate:false,
    is_schedule_order_interval: false,
   
   },
   project_details:{
    appName: 'FoodSign',
    appTitle: 'FoodSign',
    titleSperator : ' | ',
    apiUrl:"https://api.foodsign.apps.eximuz.com/api/v1",
    logo:'../assets/images/logo1.jpg',
    icon: 'assets/images/icon.png"',
    footer_name: 'FoodSign'
   }
};
export const environmentAirlines = {
  production: false,
  firebase : {
    apiKey: "AIzaSyAFJfO0iXGBZpFwJrriBRC1F4TjVvjyJlo",
    authDomain: "airlines-f65cd.firebaseapp.com",
    databaseURL: "https://airlines-f65cd.firebaseio.com",
    projectId: "airlines-f65cd",
    storageBucket: "airlines-f65cd.appspot.com",
    messagingSenderId: "909612231661",
    appId: "1:909612231661:web:de3cc7b86c9987fd8a95e4",
    measurementId: "G-38CT4QS3FG"

  },
  menu:{
    is_dashboard:true,
    is_catalog:true,
    is_order : true,
    is_customer : true,
    is_location : true,
    is_vendor : false,
    is_promotion_banner : true,
    is_admin_user : true,
    is_offer : true,
    is_customer_membership : false,
    is_delivery_boy : true,
    is_settings : true,
    is_Schemes:false,
    is_pickup:false,
    is_social_media:false,
    is_notification:false,
  },
  sub_menu:{
    is_catalog_lunchbox : false,
    is_vendor_categories:false,
    is_coupon:true,
    is_settings_tax:true,
    is_settings_delivery: false,
    is_settings_spin: false,
    is_settings_premium: false,
    is_settings_settings: true,
    is_customer_group:false,
    product_count:false,
    is_floatingCash:false,
    is_delivery_boyTracking:false,
    is_delivery_boycommition:false,
    is_delivery_boyattendance:false,
    is_deliveryTeams:false,
    product_calory:false,
    enable_state_location:false,
    enabled_arabic:false,
    is_addVendor:false,
    is_scheme:false
   },
   common_label:{
    city_label :'City',
    location_label :'Location',
    vendor_label_name :'Branch',
    commission_module: false,
    use_wallet: true,
    is_Password:false,
    is_Approve:false,
    is_Price:'RS',
    is_bulkProduct:false,
    coin_label:'Airlines Credit',
    location_view_label:'Taluk',
    coin_rate:false,
    is_schedule_order_interval: false,
   },
   project_details:{
    appName: 'Airlines',
    appTitle: 'Airlines',
    titleSperator : ' | ',
    apiUrl:"https://api.app.airlinescelestial.com/api/v1",
    logo:'../assets/images/logo2.png',
    icon: 'assets/images/icon2.png"',
    footer_name: 'Airlines'
   }
};
export const environmentGrillax = {
  production: true,
  firebase : {
    apiKey: "AIzaSyCPRKbiesBOc2fpos_XqWiYndCrehzmtm4",
    authDomain: "grillax-a6174.firebaseapp.com",
    databaseURL: "https://grillax-a6174.firebaseio.com",
    projectId: "grillax-a6174",
    storageBucket: "grillax-a6174.appspot.com",
    messagingSenderId: "292012486309",
    appId: "1:292012486309:web:6ec30543bf853c98ba274c",
    measurementId: "G-4SSTTH2ZQM"
  },
  menu:{
    is_dashboard:true,
    is_catalog:true,
    is_order : true,
    is_customer : true,
    is_location : true,
    is_vendor : true,
    is_promotion_banner : true,
    is_admin_user : true,
    is_offer : true,
    is_customer_membership : false,
    is_delivery_boy : true,
    is_settings : true,
    is_Schemes:false,
    is_pickup:false,
  },
  sub_menu:{
    is_catalog_lunchbox : false,
    is_vendor_categories:false,
    is_coupon:true,
    is_settings_tax: true,
    is_settings_delivery: false,
    is_settings_spin: false,
    is_settings_premium: false,
    is_settings_settings: true,
    is_customer_group:false,
    is_addVendor:true,
    is_floatingCash:true,
    is_delivery_pickup:false,
    is_delivery_boyTracking:true,
    is_delivery_boycommition:true,
    is_delivery_boyattendance:true,
    is_deliveryTeams:true,
    product_count:false,
   },
   common_label:{
    city_label :'City',
    location_label :'Location',
    vendor_label_name :'Branch',
    commission_module: false,
    use_wallet: true,
    delivery_status:'Deliverd',
    is_Password:false,
    cash_on_status:"Delivery",
    is_Approve:false,
    is_Price:'RS',
    is_bulkProduct:false,
    coin_label:'Grillax Credit',
    is_schedule_order_interval: false,
   },
   project_details:{
    appName: 'Grillax',
    appTitle: 'Grillax',
    titleSperator : ' | ',
    apiUrl:"https://api.app.thegrillax.com/api/v1",
    logo:'../assets/images/logo3.jpg',
    icon: 'assets/images/icon3.jpg"',
    footer_name: 'Grillax'
   }
};

export const environmentAirlinesKart = {
  production: true,
  firebase : {
    apiKey: "AIzaSyDpq3Hi0a4uS1uC1eZGOiZ6glKUSZBd4J8",
    authDomain: "airlines-kart.firebaseapp.com",
    databaseURL: "https://airlines-kart.firebaseio.com",
    projectId: "airlines-kart",
    storageBucket: "airlines-kart.appspot.com",
    messagingSenderId: "181248108441",
    appId: "1:181248108441:web:c480856d3c4ac67823d508",
    measurementId: "G-HBNZMPCMZL"
  },
  menu:{
    is_dashboard:true,
    is_catalog:true,
    is_order : true,
    is_customer : true,
    is_location : true,
    is_vendor : true,
    is_promotion_banner : true,
    is_admin_user : true,
    is_offer : true,
    is_customer_membership : false,
    is_delivery_boy : true,
    is_settings : true,
    is_Schemes:false,
    is_pickup:true,
  },
  sub_menu:{
    is_catalog_lunchbox : false,
    is_vendor_categories:false,
    is_coupon:true,
    is_settings_tax: false,
    is_settings_delivery: false,
    is_settings_spin: false,
    is_settings_premium: false,
    is_settings_settings: true,
    is_customer_group:false,
    product_count:false,
    is_addVendor:true,
   },
   common_label:{
    city_label :'City',
    location_label :'Location',
    vendor_label_name :'Branch',
    commission_module: false,
    use_wallet: true,
    is_Password:false,
    is_Approve:false,
    is_Price:'RS',
    is_bulkProduct:false,
    coin_label:'Airlines Kart Credit',
    is_schedule_order_interval: false,
   },
   project_details:{
    appName: 'Airlines Kart',
    appTitle: 'Airlines Kart',
    titleSperator : ' | ',
    apiUrl:"https://api.app.airlineskart.com/api/v1",
    logo:'../assets/images/logo4.png',
    icon: 'assets/images/icon4.png"',
    footer_name: 'Airlines Kart'
   }
};


export const environmentAnjos = {
  production: true,
  firebase : {
    apiKey: "AIzaSyCPRKbiesBOc2fpos_XqWiYndCrehzmtm4",
    authDomain: "grillax-a6174.firebaseapp.com",
    databaseURL: "https://grillax-a6174.firebaseio.com",
    projectId: "grillax-a6174",
    storageBucket: "grillax-a6174.appspot.com",
    messagingSenderId: "292012486309",
    appId: "1:292012486309:web:6ec30543bf853c98ba274c",
    measurementId: "G-4SSTTH2ZQM"
  },
  menu:{
    is_dashboard:true,
    is_catalog:true,
    is_order : true,
    is_customer : true,
    is_location : true,
    is_vendor : true,
    is_promotion_banner : true,
    is_admin_user : true,
    is_offer : true,
    is_customer_membership : true,
    is_delivery_boy : true,
    is_settings : true,
    is_Schemes:false,
    is_pickup:false,
  },
  sub_menu:{
    is_catalog_lunchbox : true,
    is_vendor_categories:true,
    is_coupon:true,
    is_settings_tax: true,
    is_settings_delivery: true,
    is_settings_spin: true,
    is_settings_premium: true,
    is_settings_settings: true,
    is_customer_group:false,
    product_count:false,
   },
   common_label:{
    city_label :'Panchayath/Municipality',
    location_label :'Location',
    vendor_label_name :'Vendor',
    commission_module: false,
    use_wallet: true,
    is_Password:true,
    is_Approve:true,
    is_Price:'RS',
    is_bulkProduct:false,
    coin_label:'Anjose Coin',
    is_schedule_order_interval: false,
   },
   project_details:{
    appName: 'Anjos',
    appTitle: 'Anjos',
    titleSperator : ' | ',
    apiUrl:"https://api.app.thegrillax.com/api/v1",
    logo:'../assets/images/logo3.jpg',
    icon: 'assets/images/icon3.jpg"',
    footer_name: 'Anjos'
   }
};

export const environmentKriztle = {
  production: true,
  firebase : {
    apiKey: "AIzaSyDYV0x2I-8ujAsVrAKr536RqwqSjoTB3hI",
    authDomain: "foodsign-87cbd.firebaseapp.com",
    databaseURL: "https://foodsign-87cbd.firebaseio.com",
    projectId: "foodsign-87cbd",
    storageBucket: "foodsign-87cbd.appspot.com",
    messagingSenderId: "490291510877",
    appId: "1:490291510877:web:175d65990a52bb875417b4",
    measurementId: "G-E8HVTQT505"
  },
  menu:{
    is_dashboard:true,
    is_catalog:true,
    is_order : true,
    is_customer : true,
    is_location : true,
    is_vendor : false,
    is_promotion_banner : false,
    is_admin_user : true,
    is_offer : false,
    is_customer_membership : false,
    is_delivery_boy : false,
    is_settings : true,
    is_Schemes:true,
    is_pickup:false,
   
  },
  sub_menu:{
    is_catalog_lunchbox : false,
    is_vendor_categories:true,
    is_coupon:true,
    is_settings_tax: true,
    is_settings_delivery: true,
    is_settings_spin: false,
    is_settings_premium: false,
    is_settings_settings: true,
    is_customer_group:true,
    is_addVendor:false,
    is_floatingCash:false,
    is_delivery_boyTracking:false,
    is_delivery_boycommition:false,
    is_delivery_boyattendance:false,
    is_deliveryTeams:false,
    product_count:true,
    enable_state_location:false,
    product_calory:false,
    enabled_arabic:false,
    is_scheme:true
   },
   common_label:{
    city_label :'District',
    location_label :'Location',
    vendor_label_name :'Vendor',
    commission_module: false,
    use_wallet: true,
    is_Password:true,
    is_Approve:true,
    coin_rate:false,
    is_Price:'RS',
    is_bulkProduct:true,
    coin_label:'kriztla Credit',
    location_view_label:'Taluk',
    is_schedule_order_interval: false,
   },
   project_details:{
    appName: 'Kriztle',
    appTitle: 'Kriztle',
    titleSperator : ' | ',
    apiUrl:"https://api.kriztle.foodsign.eximuz.com/api/v1",
    logo:'../assets/images/Kriztle-Logo.png',
    icon: 'assets/images/Kriztle-Logo.png"',
    footer_name: 'Kriztle'
   }
};


export const environmentMigos = {
  production: false,
  firebase : {
    apiKey: "AIzaSyDYV0x2I-8ujAsVrAKr536RqwqSjoTB3hI",
    authDomain: "foodsign-87cbd.firebaseapp.com",
    databaseURL: "https://foodsign-87cbd.firebaseio.com",
    projectId: "foodsign-87cbd",
    storageBucket: "foodsign-87cbd.appspot.com",
    messagingSenderId: "490291510877",
    appId: "1:490291510877:web:175d65990a52bb875417b4",
    measurementId: "G-E8HVTQT505"
  },
  menu:{
    is_dashboard:true,
    is_catalog:true,
    is_order : true,
    is_customer : true,
    is_location : true,
    is_vendor : true,
    is_promotion_banner : true,
    is_admin_user : true,
    is_offer : true,
    is_customer_membership : false,
    is_delivery_boy : true,
    is_settings : true,
    is_Schemes:true,
    is_pickup:true,
  },
  sub_menu:{
    is_catalog_lunchbox : false,
    is_vendor_categories:false,
    is_coupon: true,
    is_settings_tax: false,
    is_settings_delivery: false,
    is_settings_spin: false,
    is_settings_premium: false,
    is_settings_settings: true,
    is_customer_group:false,
    is_addVendor:false,
    is_floatingCash:true,
    is_delivery_boyTracking:true,
    is_delivery_boycommition:true,
    is_delivery_boyattendance:true,
    is_deliveryTeams:true,
    product_count:false,
   },
   common_label:{
    city_label :'City',
    location_label :'Location',
    vendor_label_name :'Branch',
    commission_module: false,
    use_wallet: true,
    delivery_salary:false,
    delivery_commission_type:false,
    is_Password:false,
    is_Approve:false,
    is_Price:'RS',
    is_bulkProduct:false,
    coin_label:'Foodsign Credit',
    is_schedule_order_interval: false,
   
   },
   project_details:{
    appName: 'Migos',
    appTitle: 'Migos',
    titleSperator : ' | ',
    apiUrl:"https://api.migos.foodsign.eximuz.com/api/v1",
    logo:'../assets/images/logo5.png',
    icon: 'assets/images/icon.png"',
    footer_name: 'Migos'
   }
};

export const environmentMezaj = {
  production: false,
  firebase : {
    apiKey: "AIzaSyDziO773fmwLhLv-k7HNhNu2Ipqob_CGg0",
    authDomain: "mezaj-2a850.firebaseapp.com",
    projectId: "mezaj-2a850",
    storageBucket: "mezaj-2a850.appspot.com",
    messagingSenderId: "914977073561",
    appId: "1:914977073561:web:52c3bbfce99b1fd91c8b08",
    measurementId: "G-3R6B5N6PYQ"
  },
  menu:{
    is_dashboard:true,
    is_catalog:true,
    is_order : true,
    is_customer : true,
    is_location : false,
    is_vendor : false,
    is_promotion_banner : true,
    is_admin_user : true,
    is_offer : true,
    is_customer_membership : false,
    is_delivery_boy : false,
    is_settings : true,
    is_Schemes:false,
    is_pickup:false,
    is_social_media:true,
    is_notification:true,
    is_social_post:true,
    is_gift_template:true,
    is_branch_dashboard:true
  },
  sub_menu:{
    is_catalog_lunchbox : false,
    is_vendor_categories:false,
    is_coupon: true,
    is_settings_tax: false,
    is_settings_delivery: false,
    is_settings_spin: false,
    is_settings_premium: false,
    is_settings_settings: true,
    is_customer_group:false,
    is_addVendor:true,
    is_floatingCash:false,
    is_delivery_boyTracking:false,
    is_delivery_boycommition:false,
    is_delivery_boyattendance:false,
    is_deliveryTeams:false,
    product_count:false,
    product_calory:true,
    enabled_arabic:true,
    enable_state_location:false,
    is_scheme:false,
    is_delivery_pickup:true,
    is_exicutieve_review:false,
    is_custom_offer_push:true,
    is_manufacture:false
   },
   common_label:{
    city_label :'City',
    location_label :'Location',
    vendor_label_name :'Branch',
    commission_module: false,
    use_wallet: true,
    delivery_salary:false,
    delivery_commission_type:false,
    is_Password:false,
    is_Approve:false,
    coin_rate:false,
    location_view_label:'Taluk',
    is_Price:'SAR',
    is_bulkProduct:false,
    coin_label:'Mezaj Credit',
    delivery_status:'Pickup',
    cash_on_status:'Pick up',
    is_slug:false,
    add_branch_instant_time:false,
    add_branch_scheduled_time:false,
    is_shipping_address:false,
    is_schedule_order_interval: true,
    is_image_browser:false,
    is_product_rating:false,
    is_available_time:false,
    is_credit:false,
    is_size_fit:false,
    is_shipping_return:false,
    is_deatails_care:false,
    notification_all_offers: false,
    order:{
      is_toAddress:false,
      is_billingAddress:false,
      dashbord_categories:false,
      dashboard_products:false
    },
    product_is_current_stock:false,
    product_is_related_product:false
   },
   project_details:{
    appName: 'Mezaj',
    appTitle: 'Mezaj',
    titleSperator : ' | ',
    apiUrl:"https://api.mezaj.foodsign.eximuz.com/api/v1",
    logo:'../assets/images/logo.svg',
    icon: 'assets/images/logo.svg"',
    footer_name: 'Mezaj'
   }
};


export const environmentKhaaz = {
  production: false,
  firebase : {
    apiKey: "AIzaSyDziO773fmwLhLv-k7HNhNu2Ipqob_CGg0",
    authDomain: "mezaj-2a850.firebaseapp.com",
    projectId: "mezaj-2a850",
    storageBucket: "mezaj-2a850.appspot.com",
    messagingSenderId: "914977073561",
    appId: "1:914977073561:web:52c3bbfce99b1fd91c8b08",
    measurementId: "G-3R6B5N6PYQ"
  },
  menu:{
    is_dashboard:true,
    is_catalog:true,
    is_order : true,
    is_customer : true,
    is_location : true,
    is_vendor : false,
    is_promotion_banner : true,
    is_admin_user : true,
    is_offer : true,
    is_customer_membership : false,
    is_delivery_boy : false,
    is_settings : false,
    is_Schemes:false,
    is_pickup:false,
    is_social_media:true,
    is_notification:true,
    is_social_post:true,
    is_branch_dashboard:false,
    is_gift_template: true
  },
  sub_menu:{
    is_catalog_lunchbox : false,
    is_vendor_categories:false,
    is_coupon: true,
    is_settings_tax: false,
    is_settings_delivery: false,
    is_settings_spin: false,
    is_settings_premium: false,
    is_settings_settings: true,
    is_customer_group:false,
    is_addVendor:true,
    is_floatingCash:false,
    is_delivery_boyTracking:false,
    is_delivery_boycommition:false,
    is_delivery_boyattendance:false,
    is_deliveryTeams:false,
    product_count:false,
    product_calory:false,
    enabled_arabic:false,
    enable_state_location:true,
    is_scheme:false,
    is_delivery_pickup:false,
    is_exicutieve_review:false,
    is_custom_offer_push:false,
    is_manufacture:true
   },
   common_label:{
    city_label :'City',
    location_label :'Location',
    vendor_label_name :'Branch',
    commission_module: false,
    use_wallet: true,
    delivery_salary:false,
    delivery_commission_type:false,
    is_Password:false,
    is_Approve:false,
    coin_rate:false,
    location_view_label:'Taluk',
    is_Price:'Rs.',
    is_bulkProduct:false,
    coin_label:'Khaaz Credit',
    delivery_status:'Delivered',
    cash_on_status:'Pick up',
    is_slug:true,
    add_branch_instant_time:true,
    add_branch_scheduled_time:true,
    is_shipping_address:false,
    is_schedule_order_interval: false,
    is_available_time: false,
    is_product_rating: false,
    is_credit:false,
    is_image_browser:false,
    notification_all_offers:false,
    order:{
      is_toAddress:false,
      is_billingAddress:false,
      dashbord_categories:false,
      dashboard_products:false
    },
    product_is_current_stock:true,
    product_is_related_product:true,

    is_deatails_care: false,
    is_shipping_return: false,
    is_size_fit: false

   },
   project_details:{
    appName: 'Khaaz',
    appTitle: 'Khaaz',
    titleSperator : ' | ',
    apiUrl:"https://api.khaaz.foodsign.eximuz.com/api/v1",
    logo:'../assets/images/khaaz_logo.png',
    icon: 'assets/images/khaaz_logo.png"',
    footer_name: 'Khaaz'
   }
};