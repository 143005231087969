import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from "@angular/router";
import { LocationStrategy, HashLocationStrategy, CommonModule } from '@angular/common';

import { ROUTES, AppRoutingModule, LoginActivate, dashBoardActvate } from "./app.routes";
import { AppComponent } from './app.component';

// App views
import { AppviewsModule } from "./views/appviews/appviews.module";

// App modules/components
import { LayoutsModule } from "./components/common/layouts/layouts.module";
import { RegisterComponent } from './views/appviews/register/register.component';
// import { OrderModule } from './views/order/order.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonProvider } from './providers/common';
import { ToastrModule } from 'ngx-toastr';
import { Profile } from './providers/profile.service';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { UserData } from './providers/user-data';
import { UsersManagementModule } from './views/users-management/users-management.module';
import { OrderService } from './providers/orderService';
import { MatFormFieldModule, MatSnackBarModule, MatDialogModule, MatProgressBarModule, MatProgressSpinnerModule, MatTableModule, MatPaginatorModule, MatRadioModule, MatInputModule, MatSelectModule, MatSortModule } from '@angular/material';
import { WidgetsModule } from './components/common/widgets/widgets.module';
import {MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'environments/environment';
import { MessagingService } from './providers/messaging.service';
import { AddNotificationComponent } from './views/notification/add-notification/add-notification.component';
import { SelectAutocompleteModule } from 'mat-select-autocomplete';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';



@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    AddNotificationComponent
  ],
  imports: [
    // BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpModule,
    LayoutsModule,
    AppviewsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({ preventDuplicates: true }),
    StorageServiceModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    UsersManagementModule,
    WidgetsModule,
    MatSnackBarModule,
    MatDialogModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatTableModule,
    MatPaginatorModule,
    MatRadioModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    RouterModule.forChild(ROUTES),
    MatSortModule,
    SelectAutocompleteModule,
    RichTextEditorAllModule
    ],
  providers: [
    MessagingService,
    CommonProvider,
    UserData,
    OrderService,
    FormBuilder,
    LoginActivate,
    dashBoardActvate,
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { height: '100%',
    panelClass: 'form-modal-container',
    hasBackdrop: false,
    autoFocus:true,
    position:{  right: '0' }}}
  ],
  entryComponents:[
    AddNotificationComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
