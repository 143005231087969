import { Routes, RouterModule, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { StarterViewComponent } from "./views/appviews/starterview.component";
import { LoginComponent } from "./views/appviews/login/login.component";
import { BlankLayoutComponent } from "./components/common/layouts/blankLayout.component";
import { BasicLayoutComponent } from "./components/common/layouts/basicLayout.component";
import { TopNavigationLayoutComponent } from "./components/common/layouts/topNavigationLayout.component";
import { RegisterComponent } from './views/appviews/register/register.component';
import { OrderListComponent } from './views/order/order-list/order-list.component';
import { OrderViewComponent } from './views/order/order-view/order-view.component';
import { UsersListComponent } from './views/users-management/users-list/users-list.component';
import { UserViewComponent } from './views/users-management/user-view/user-view.component';
import { NgModule, Injectable, Inject } from '@angular/core';
import { ProfileComponent } from './views/appviews/profile/profile.component';
import { AddUserComponent } from './views/users-management/add-user/add-user.component';
import { UserData } from './providers/user-data';
import { CommonProvider } from './providers/common';
import { Observable } from 'rxjs';
import { StorageService, LOCAL_STORAGE } from 'ngx-webstorage-service';
import { DashboardBranchComponent } from "./views/appviews/dashboard-branch/dashboard-branch.component";

@Injectable()
export class LoginActivate implements CanActivate {
  constructor(private user: UserData, public common: CommonProvider,
    @Inject(LOCAL_STORAGE) private storage: StorageService, private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.storage.get(this.user.HAS_LOGGED_IN)) {
      this.common.showToast('Please log in to continue');
      this.router.navigate(['/login']);
      return;
    }
    return true;
  }
}


@Injectable()
export class dashBoardActvate implements CanActivate {
  constructor(private user: UserData, public common: CommonProvider,
    @Inject(LOCAL_STORAGE) private storage: StorageService, private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.common.is_branch_dashboard) {
      console.log(this.common.is_branch_dashboard)
      this.router.navigate(['/branch']);
      return;
    }
    return true;
  }
}



export const ROUTES: Routes = [
  // Main redirect
  { path: '', redirectTo: 'dashboard/view', pathMatch: 'full' },

  // App views

  {
    path: 'dashboards', component: TopNavigationLayoutComponent,
    children: [
    ]
  },
  {
    path: '', component: BasicLayoutComponent,
    children: [
      { path: 'dashboard/view', component: StarterViewComponent, canActivate: [LoginActivate,dashBoardActvate] },
    ]
  },

  {
    path: '', component: BasicLayoutComponent,
    children: [
      { path: 'dashboard', component: StarterViewComponent, canActivate: [LoginActivate] },
    ]
  },

  {
    path: '', component: BasicLayoutComponent,
    children: [
      { path: 'branch', component: DashboardBranchComponent },
    ]
  },
  {
    path: '', component: BlankLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
    ]
  },
  {
    path: 'order', component: BasicLayoutComponent, canActivate: [LoginActivate],
    loadChildren: './views/order/order.module#OrderModule',
    data: { title: 'Orders' }
  },



  {
    path: 'gift-template', component: BasicLayoutComponent, canActivate: [LoginActivate],
    loadChildren: './views/gift-template/gift-template.module#GiftTemplateModule',
    data: { title: 'Gift Template' }
  },

  {
    path: 'gallery', component: BasicLayoutComponent, canActivate: [LoginActivate],
    loadChildren: './views/gallery/gallery.module#GalleryModule',
    data: { title: 'Gallery List' }
  },


  {
    path: 'user', component: BasicLayoutComponent, canActivate: [LoginActivate],
    loadChildren: './views/users-management/users-management.module#UsersManagementModule',
    data: { title: 'Users' }
  },

  {
    path: 'vendor', component: BasicLayoutComponent, canActivate: [LoginActivate],
    loadChildren: './views/vendor/vendor.module#VendorModule',
    data: { title: 'Vendors' }

  },
  {
    path: 'catalog', component: BasicLayoutComponent, canActivate: [LoginActivate],
    loadChildren: './views/catalogue/catalogue.module#CatalogueModule',
    data: { title: 'Catalog' }
  },
  {
    path: 'location', component: BasicLayoutComponent, canActivate: [LoginActivate],
    loadChildren: './views/location/location.module#LocationModule',
    data: { title: 'Location' }
  },
  {
    path: 'gift-product', component: BasicLayoutComponent, canActivate: [LoginActivate],
    loadChildren: './views/gift-product/gift-product.module#GiftProductModule',
    data: { title: 'Gift Product' }
  },
  {
    path: 'promotion-banners', component: BasicLayoutComponent, canActivate: [LoginActivate],
    loadChildren: './views/promotion-banners/promotion-banners.module#PromotionBannersModule',
    data: { title: 'Promotion Banner' }
  },
  {
    path: 'membership', component: BasicLayoutComponent, canActivate: [LoginActivate],
    loadChildren: './views/customer-membership/customer-membership.module#CustomerMembershipModule',
    data: { title: 'Customer Membership' }
  },
  {
    path: 'Schemes', component: BasicLayoutComponent, canActivate: [LoginActivate],
    loadChildren: './views/Schemes/schemes.module#SchemesModule',
    data: { title: 'membership' }
  },
  {
   path:'pickup' , component:BasicLayoutComponent,canActivate:[LoginActivate],
   loadChildren:'./views/pickup-points/pickup-points.module#PickupPointsModule',
   data:{title:'membership'}
  },
  {
    path: 'offers', component: BasicLayoutComponent, canActivate: [LoginActivate],
    loadChildren: './views/offers/offers.module#OffersModule',
    data: { title: 'Offers' }
  },
  {
    path: 'customer', component: BasicLayoutComponent, canActivate: [LoginActivate],
    loadChildren: './views/customers/customers.module#CustomersModule',
    data: { title: 'Customer' }
  },
  {
    path: 'settings', component: BasicLayoutComponent, canActivate: [LoginActivate],
    loadChildren: './views/settings/settings.module#SettingsModule',
    data: { title: 'Settings' }
  },
  {
    path: 'delivery-boy-management', component: BasicLayoutComponent, canActivate: [LoginActivate],
    loadChildren: './views/delivery-boy-management/delivery-boy-management.module#DeliveryBoyManagementModule',
    data: { title: 'Delivery Boy Management' }
  },
  {
    path: 'login-history', component: BasicLayoutComponent, canActivate: [LoginActivate],
    loadChildren: './views/login-history/login-history.module#LoginHistoryModule',
    data: { title: 'Login History' }
  },
  {
    path: 'social-media', component: BasicLayoutComponent, canActivate: [LoginActivate],
    loadChildren: './views/social_media/social-media.module#SocialMediaModule',
    data: { title: 'Social Media' }
  },
  {
    path: 'notification', component: BasicLayoutComponent, canActivate: [LoginActivate],
    loadChildren: './views/notification/notification.module#NotificationModule',
    data: { title: 'Notification' }
  },
  // {
  //   path: 'dashboard-branch', component: BasicLayoutComponent,
  //   children: [
  //     { path: 'dashboard-branch', component: DashboardBranchComponent, canActivate: [LoginActivate] },
  //   ]
  // },
  // Handle all other routes
  { path: '**', redirectTo: 'dashboard/view' }
];
@NgModule({
  imports: [RouterModule.forRoot(ROUTES, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
